var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "view-wrap" }, [
    _c("div", { staticClass: "view-content" }, [
      _c("div", { staticClass: "content-horizontal" }, [
        _c("div", { staticClass: "horizontal-inner" }, [
          _c("section", { attrs: { id: "top-ribbon" } }, [
            _c("div", { staticClass: "section-inner" }, [
              _vm._m(0),
              _vm._v(" "),
              _c("div", { staticClass: "mobile-menu" }, [
                _c(
                  "button",
                  {
                    class: { selected: _vm.mobileSelect === "free" },
                    on: {
                      click: function ($event) {
                        _vm.mobileSelect = "free"
                      },
                    },
                  },
                  [_vm._v("Free")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    class: { selected: _vm.mobileSelect === "yearly" },
                    on: {
                      click: function ($event) {
                        _vm.mobileSelect = "yearly"
                      },
                    },
                  },
                  [_vm._v("1 Year")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    class: { selected: _vm.mobileSelect === "monthly" },
                    on: {
                      click: function ($event) {
                        _vm.mobileSelect = "monthly"
                      },
                    },
                  },
                  [_vm._v("1 Month")]
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("section", { attrs: { id: "bear-images" } }, [
            _c("div", { staticClass: "section-inner" }, [
              _c("div", { staticClass: "container" }, [
                _c(
                  "div",
                  { staticClass: "bears", class: "select-" + _vm.mobileSelect },
                  [_vm._m(1), _vm._v(" "), _vm._m(2), _vm._v(" "), _vm._m(3)]
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("section", { attrs: { id: "bear-plans" } }, [
            _c("div", { staticClass: "section-inner" }, [
              _c("div", { staticClass: "container" }, [
                _c(
                  "div",
                  { staticClass: "row", class: "select-" + _vm.mobileSelect },
                  [
                    _c(
                      "div",
                      { staticClass: "col-12 col-md-4 item-free item-column" },
                      [
                        _c(
                          "div",
                          { staticClass: "content" },
                          [
                            _c("h2", { staticClass: "h2" }, [_vm._v("Free")]),
                            _vm._v(" "),
                            _vm._m(4),
                            _vm._v(" "),
                            _c("p", { staticClass: "copy" }, [
                              _vm._v(
                                "Find out if TunnelBear works for you. Upgrade at any time for unlimited data."
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "router-link",
                              {
                                staticClass: "btn btn-lg",
                                attrs: {
                                  "aria-label":
                                    "Download TunnelBear for Free with a data limit of 2GB per month",
                                  to: { name: "download" },
                                },
                              },
                              [_vm._v("Download")]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "col-12 col-md-4 item-monthly item-column",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "content" },
                          [
                            _c("h2", { staticClass: "h2" }, [
                              _vm._v("1 Month"),
                            ]),
                            _vm._v(" "),
                            _vm._m(5),
                            _vm._v(" "),
                            _c("div", { staticClass: "pricing" }, [
                              _c("p", [
                                _c("span", { staticClass: "amount" }, [
                                  _vm._v(
                                    "$" +
                                      _vm._s(
                                        _vm.pricing.MONTHLY_PAYMENT.regularPrice
                                      )
                                  ),
                                ]),
                                _vm._v("/month"),
                              ]),
                              _vm._v(" "),
                              _c("p", { staticClass: "fine-print-lg" }, [
                                _vm._v(
                                  "$" +
                                    _vm._s(
                                      _vm.pricing.MONTHLY_PAYMENT.regularPrice
                                    ) +
                                    " billed every month"
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "router-link",
                              {
                                staticClass: "btn btn-lg",
                                attrs: {
                                  "aria-label":
                                    "Get Unlimited TunnelBear for $" +
                                    _vm.pricing.MONTHLY_PAYMENT.regularPrice +
                                    "USD per month",
                                  to: {
                                    name: "checkout",
                                    query: { plan: "monthly" },
                                  },
                                },
                              },
                              [_vm._v("Get 1 Month")]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "col-12 col-md-4 item-yearly item-column",
                      },
                      [
                        _c("div", { staticClass: "save-circle" }, [
                          _c("p", { staticClass: "save" }, [
                            _vm._v("Save " + _vm._s(_vm.grizzlyDiscount) + "%"),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "content" },
                          [
                            _c("h2", { staticClass: "h2" }, [_vm._v("1 Year")]),
                            _vm._v(" "),
                            _vm._m(6),
                            _vm._v(" "),
                            _c("div", { staticClass: "pricing" }, [
                              _c("p", [
                                _c(
                                  "span",
                                  { staticClass: "amount accent-text" },
                                  [
                                    _vm._v(
                                      "$" +
                                        _vm._s(
                                          (_vm.currentPrice / 12).toFixed(2)
                                        )
                                    ),
                                  ]
                                ),
                                _vm._v("/month"),
                              ]),
                              _vm._v(" "),
                              _c("p", { staticClass: "fine-print-lg" }, [
                                _vm._v(
                                  " $" +
                                    _vm._s(_vm.currentPrice) +
                                    " billed every 12 months"
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "router-link",
                              {
                                staticClass: "btn btn-lg",
                                attrs: {
                                  "aria-label":
                                    "Get Unlimited TunnelBear for $" +
                                    _vm.currentPrice +
                                    "USD per year",
                                  to: {
                                    name: "checkout",
                                    query: {
                                      plan: "yearly",
                                      couponCode: "BLACKFRIDAY2019",
                                    },
                                  },
                                },
                              },
                              [_vm._v("Get 1 Year")]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm._m(7),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "floating-title" }, [
      _c("h1", [_vm._v("Choose your plan")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "bear-pic item-free" }, [
      _c("img", {
        staticClass: "free hidden-xs",
        attrs: { src: require("./images/bear-free.svg"), alt: "" },
      }),
      _vm._v(" "),
      _c("img", {
        staticClass: "free visible-xs",
        attrs: { src: require("./images/mobile-free.svg"), alt: "" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "bear-pic item-monthly" }, [
      _c("img", {
        staticClass: "monthly hidden-xs",
        attrs: { src: require("./images/bear-monthly.svg"), alt: "" },
      }),
      _vm._v(" "),
      _c("img", {
        staticClass: "monthly visible-xs",
        attrs: { src: require("./images/mobile-monthly.svg"), alt: "" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "bear-pic item-yearly" }, [
      _c("img", {
        staticClass: "yearly",
        attrs: {
          srcset:
            require("./images/bear-yearly.png") +
            " 1x, " +
            require("./images/bear-yearly@2x.png") +
            " 2x",
          src: require("./images/bear-yearly@2x.png"),
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", { staticClass: "check-list yellow copy" }, [
      _c("li", [_vm._v("2GB of data a month")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", { staticClass: "check-list copy" }, [
      _c("li", [_vm._v("Unlimited data")]),
      _vm._v(" "),
      _c("li", [_vm._v("Unlimited devices")]),
      _vm._v(" "),
      _c("li", [_vm._v("Priority customer service")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", { staticClass: "check-list copy" }, [
      _c("li", [_vm._v("Unlimited data")]),
      _vm._v(" "),
      _c("li", [_vm._v("Unlimited devices")]),
      _vm._v(" "),
      _c("li", [_vm._v("Priority customer service")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "payment-info" }, [
      _c("img", {
        staticClass: "payment-img",
        attrs: {
          src: require("./images/payment-options.svg"),
          alt: "Acceptable payment types include Visa, Mastercard, American Express, Jars of Honey",
        },
      }),
      _vm._v(" "),
      _c("p", { staticClass: "fine-print-lg payment-note col-xs-12" }, [
        _vm._v("All pricing shown in USD"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }