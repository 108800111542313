var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "nav-menu" }, [
    _c(
      "div",
      { staticClass: "nav-items-wrap" },
      [
        _c("nav-link-vpn", {
          attrs: { current: _vm.current === "what-is-vpn" },
          on: {
            click: function ($event) {
              return _vm.$emit("closeNav")
            },
          },
        }),
        _vm._v(" "),
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "nav-item",
            class: { current: _vm.current === "overview" },
            on: {
              click: function ($event) {
                return _vm.$emit("closeNav")
              },
            },
          },
          [
            _c("router-link", { attrs: { to: { name: "overview" } } }, [
              _vm._v("My Account"),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "nav-item visible-xs" }, [
          _c(
            "button",
            {
              staticClass: "plain",
              on: {
                click: function ($event) {
                  return _vm.$emit("logout")
                },
              },
            },
            [_vm._v("Logout")]
          ),
        ]),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "nav-item bottom",
        on: {
          click: function ($event) {
            return _vm.$emit("closeNav")
          },
        },
      },
      [
        _c(
          "router-link",
          {
            staticClass: "btn",
            class: { hollow: _vm.current === "download" },
            attrs: { to: { name: "download" } },
          },
          [_vm._v("Download")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "nav-item" }, [
      _c("a", { attrs: { href: "https://help.tunnelbear.com/hc/en-us" } }, [
        _vm._v("Help"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }