var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "nav-menu" }, [
    _vm._m(0),
    _vm._v(" "),
    _vm._m(1),
    _vm._v(" "),
    _c("div", { staticClass: "nav-item visible-xs" }, [
      _c(
        "button",
        {
          staticClass: "plain",
          on: {
            click: function ($event) {
              return _vm.$emit("logout")
            },
          },
        },
        [_vm._v("Logout")]
      ),
    ]),
    _vm._v(" "),
    _vm._m(2),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "nav-item" }, [
      _c("a", { attrs: { href: "/team/account/overview" } }, [
        _vm._v("My Account"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "nav-item" }, [
      _c("a", { attrs: { href: "/team/account/team" } }, [_vm._v("Team")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "nav-item bottom" }, [
      _c("a", { staticClass: "btn", attrs: { href: "/team/account/invite" } }, [
        _vm._v("Invite"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }