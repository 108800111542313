var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { inert: _vm.modalOpen } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showNav,
            expression: "showNav",
          },
        ],
        staticClass: "nav-wrap",
        class: { scrolled: _vm.scrolled, "nav-minimal": _vm.minimal },
      },
      [
        _vm.showHelloBar
          ? _c("div", { staticClass: "hello-bar-block" })
          : _vm._e(),
        _vm._v(" "),
        _vm.menuType === "teams"
          ? _c("nav-teams")
          : _vm.menuType === "tb-by-mcafee"
          ? _c("nav-tb-by-mcafee")
          : _c(
              "nav",
              {
                class: {
                  tron:
                    _vm.sale.isLive &&
                    _vm.sale.name === "cyber" &&
                    _vm.current === "home",
                },
                attrs: { id: "main-header" },
              },
              [
                _vm.showHelloBar
                  ? _c("hello-bar", {
                      class: {
                        tron: _vm.sale.isLive && _vm.sale.name === "cyber",
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "nav-inner clearfix complex" }, [
                  _c(
                    "div",
                    { staticClass: "top-bar" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "logo",
                          attrs: { to: { name: "home" } },
                        },
                        [
                          _c("img", {
                            staticClass: "hidden-xs",
                            attrs: {
                              src: require("@/images/logo-grey.svg"),
                              alt: "TunnelBear",
                            },
                          }),
                          _vm._v(" "),
                          _c("img", {
                            staticClass: "visible-xs",
                            attrs: {
                              src: require("@/images/t-grey.svg"),
                              alt: "TunnelBear",
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c("button", {
                        staticClass: "plain",
                        attrs: { id: "mobile-menu", "aria-label": "menu" },
                        on: {
                          click: function ($event) {
                            return _vm.toggleMobileMenu()
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "drawer",
                      class: { "mobile-open": _vm.mobileMenuExpanded },
                    },
                    [
                      _c("div", {
                        staticClass: "screen",
                        attrs: { "aria-hidden": "true" },
                        on: {
                          click: function ($event) {
                            return _vm.toggleMobileMenu()
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        { attrs: { id: "nav-items" } },
                        [
                          _c("div", { staticClass: "mobile-controls" }, [
                            _c("button", {
                              staticClass: "plain link right close",
                              attrs: { "aria-label": "close navigation" },
                              on: {
                                click: function ($event) {
                                  return _vm.toggleMobileMenu()
                                },
                              },
                            }),
                          ]),
                          _vm._v(" "),
                          _vm.menuType === "teamAdmin"
                            ? _c("nav-team-admin", {
                                on: {
                                  logout: function ($event) {
                                    return _vm.logout()
                                  },
                                },
                              })
                            : _vm.menuType === "teamMember"
                            ? _c("nav-team-member", {
                                attrs: { current: _vm.current },
                                on: {
                                  logout: function ($event) {
                                    return _vm.logout()
                                  },
                                  closeNav: function ($event) {
                                    return _vm.toggleMobileMenu()
                                  },
                                },
                              })
                            : _vm.menuType === "paid"
                            ? _c("nav-paid", {
                                attrs: { current: _vm.current },
                                on: {
                                  logout: function ($event) {
                                    return _vm.logout()
                                  },
                                  closeNav: function ($event) {
                                    return _vm.toggleMobileMenu()
                                  },
                                },
                              })
                            : _vm.menuType === "free"
                            ? _c("nav-free", {
                                attrs: { current: _vm.current },
                                on: {
                                  logout: function ($event) {
                                    return _vm.logout()
                                  },
                                  closeNav: function ($event) {
                                    return _vm.toggleMobileMenu()
                                  },
                                },
                              })
                            : _c("div", { staticClass: "nav-menu" }, [
                                _c(
                                  "div",
                                  { staticClass: "nav-items-wrap" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "nav-item",
                                        class: {
                                          current: _vm.current === "pricing",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.toggleMobileMenu()
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            attrs: { to: { name: "pricing" } },
                                          },
                                          [_vm._v("Plans")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("nav-link-vpn", {
                                      attrs: {
                                        current: _vm.current === "what-is-vpn",
                                      },
                                      on: {
                                        dropdownClicked: function ($event) {
                                          return _vm.toggleMobileMenu()
                                        },
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "nav-item" },
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            attrs: { to: { name: "download" } },
                                          },
                                          [_vm._v("Download")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "nav-item" }, [
                                      _c(
                                        "a",
                                        {
                                          attrs: {
                                            href: "https://help.tunnelbear.com/hc/en-us",
                                          },
                                        },
                                        [_vm._v("Help")]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "nav-item",
                                        class: {
                                          current: _vm.current === "overview",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.toggleMobileMenu()
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            attrs: { to: { name: "overview" } },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.loggedIn
                                                  ? "My Account"
                                                  : "Log In"
                                              )
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "nav-item bottom",
                                    on: {
                                      click: function ($event) {
                                        return _vm.toggleMobileMenu()
                                      },
                                    },
                                  },
                                  [
                                    _vm.is.ios()
                                      ? _c(
                                          "a",
                                          {
                                            attrs: {
                                              href: _vm.external.downloadLinks
                                                .ios,
                                            },
                                          },
                                          [
                                            _c("img", {
                                              staticClass:
                                                "mobile-btn app-store",
                                              attrs: {
                                                src: require("@/images/app-store.svg"),
                                                alt: "Download on the App Store",
                                              },
                                            }),
                                          ]
                                        )
                                      : _c(
                                          "router-link",
                                          {
                                            staticClass: "btn",
                                            class: {
                                              hollow: _vm.current === "pricing",
                                            },
                                            attrs: { to: { name: "pricing" } },
                                          },
                                          [_vm._v("Get TunnelBear")]
                                        ),
                                  ],
                                  1
                                ),
                              ]),
                        ],
                        1
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "nav-inner minimal" }, [
                  _c(
                    "div",
                    { class: ["top-bar", { "custom-cta": _vm.customCta }] },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "logo",
                          attrs: { to: { name: "home" } },
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: require("@/images/tb-logo-grey.svg"),
                              alt: "TunnelBear",
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.customCta
                        ? _c(
                            "a",
                            {
                              staticClass: "btn custom-cta-link",
                              attrs: { href: _vm.customCta.link },
                            },
                            [_vm._v(_vm._s(_vm.customCta.copy))]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
        _vm._v(" "),
        _vm.is.mobile() && !_vm.isTBMcafeeLandingPage
          ? _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.mobileMenuExpanded,
                    expression: "!mobileMenuExpanded",
                  },
                ],
                staticClass: "mobile-cta-wrap",
                class: { show: _vm.mobileScrolled },
              },
              [
                _vm.showHelloBar
                  ? _c("hello-bar", {
                      attrs: { hideCtaButton: _vm.hideCtaButton },
                    })
                  : _c(
                      "div",
                      { staticClass: "mobile-cta-nav" },
                      [
                        _vm.menuType === "paid" || _vm.is.ios()
                          ? [
                              _vm.is.ios()
                                ? _c("img", {
                                    staticClass: "icon",
                                    attrs: {
                                      src: require("./images/ios-icon.svg"),
                                      alt: "",
                                    },
                                  })
                                : _c("img", {
                                    staticClass: "icon",
                                    attrs: {
                                      src: require("./images/android-icon.svg"),
                                      alt: "",
                                    },
                                  }),
                              _vm._v(" "),
                              _c("div", { staticClass: "info" }, [
                                _vm.is.ios()
                                  ? _c("div", [
                                      _c("p", [
                                        _c("strong", [
                                          _vm._v("Get TunnelBear"),
                                        ]),
                                      ]),
                                      _vm._v(" "),
                                      _c("p", [_vm._v("For iPhone & iPad")]),
                                    ])
                                  : _c("div", [
                                      _c("p", [
                                        _c("strong", [
                                          _vm._v("Get TunnelBear"),
                                        ]),
                                      ]),
                                      _vm._v(" "),
                                      _c("p", [_vm._v("For Android")]),
                                    ]),
                              ]),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  staticClass: "btn hollow",
                                  attrs: {
                                    href: _vm.is.ios()
                                      ? _vm.external.downloadLinks.ios
                                      : _vm.external.downloadLinks.android,
                                  },
                                },
                                [_vm._v("Get")]
                              ),
                            ]
                          : [
                              _c(
                                "router-link",
                                {
                                  staticClass: "logo",
                                  attrs: { to: { name: "home" } },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: require("@/images/t-grey.svg"),
                                      alt: "TunnelBear",
                                    },
                                  }),
                                ]
                              ),
                              _vm._v(" "),
                              _vm.menuType === "teams"
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "btn",
                                      attrs: { href: "/team/create" },
                                    },
                                    [_vm._v("Try it free")]
                                  )
                                : _c(
                                    "router-link",
                                    {
                                      staticClass: "btn",
                                      attrs: { to: { name: "pricing" } },
                                    },
                                    [_vm._v("Get TunnelBear")]
                                  ),
                            ],
                      ],
                      2
                    ),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }