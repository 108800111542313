var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "view-wrap" }, [
    _c(
      "div",
      { staticClass: "view-content" },
      [
        _c(
          "section",
          {
            staticClass: "bg-regular",
            class: { tron: _vm.sale.isLive && _vm.sale.name === "cyber" },
            attrs: { id: "hero" },
          },
          [
            _c("div", { staticClass: "section-inner" }, [
              _c("div", { staticClass: "container" }, [
                _c("div", { staticClass: "content" }, [
                  _c("div", { staticClass: "copy" }, [
                    _c("h1", [_vm._v("A more secure way to browse the web")]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "TunnelBear encrypts your internet connection to keep your online activity private on any network."
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "btn-holder" },
                      [
                        _vm.sale.isLive && _vm.sale.couponRoute
                          ? _c(
                              "div",
                              [
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "btn btn-lg",
                                    class: {
                                      "btn-decor":
                                        _vm.sale.isLive &&
                                        _vm.sale.name === "winter-sale",
                                    },
                                    attrs: { to: _vm.sale.couponRoute },
                                  },
                                  [
                                    _vm._v(
                                      "\n                    Spring Sale! Get"
                                    ),
                                    _c("span", { staticClass: "hidden-xs" }),
                                    _vm._v(" 67% off"),
                                  ]
                                ),
                                _vm._v(" "),
                                _vm._m(0),
                              ],
                              1
                            )
                          : _c(
                              "router-link",
                              {
                                staticClass: "btn btn-lg",
                                attrs: { to: { name: "pricing" } },
                              },
                              [_vm._v("\n                  Get TunnelBear now")]
                            ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "img-holder",
                      class: { position: !_vm.sale.isLive },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "bear" },
                        [
                          _vm.sale.isLive && _vm.sale.name === "spring-sale"
                            ? [
                                _c("img", {
                                  staticClass: "img",
                                  attrs: {
                                    src: require("./images/spring-sale-bear.svg"),
                                    alt: "fall-sale",
                                  },
                                }),
                              ]
                            : [
                                _vm.is.mobile() || _vm.is.ie()
                                  ? _c("img", {
                                      staticClass: "img offset-position",
                                      attrs: {
                                        src: require("./images/hero-bear.svg"),
                                        alt: "",
                                      },
                                    })
                                  : _c(
                                      "video",
                                      {
                                        attrs: { autoplay: "", muted: "" },
                                        domProps: { muted: true },
                                      },
                                      [
                                        _vm.highRes
                                          ? _c("source", {
                                              attrs: {
                                                src: require("./videos/hero-animation@2x.mp4"),
                                                type: "video/mp4",
                                              },
                                            })
                                          : _c("source", {
                                              attrs: {
                                                src: require("./videos/hero-animation.mp4"),
                                                type: "video/mp4",
                                              },
                                            }),
                                        _vm._v(" "),
                                        _c("img", {
                                          staticClass: "img offset-position",
                                          attrs: {
                                            src: require("./images/hero-bear.svg"),
                                            alt: "",
                                          },
                                        }),
                                      ]
                                    ),
                              ],
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _vm.is.mobile() && !_vm.sale.isLive
                        ? [
                            _vm.is.mac() || _vm.is.ios()
                              ? _c("img-set", {
                                  attrs: {
                                    classes: "device ios",
                                    webpSrc: require("@/views/home/images/devices/iphone.webp"),
                                    webpSrc2x: require("@/views/home/images/devices/iphone@2x.webp"),
                                    imgSrc: require("@/views/home/images/devices/iphone.png"),
                                    imgSrc2x: require("@/views/home/images/devices/iphone@2x.png"),
                                  },
                                })
                              : _c("img-set", {
                                  attrs: {
                                    classes: "device android",
                                    webpSrc: require("@/views/home/images/devices/android.webp"),
                                    webpSrc2x: require("@/views/home/images/devices/android@2x.webp"),
                                    imgSrc: require("@/views/home/images/devices/android.png"),
                                    imgSrc2x: require("@/views/home/images/devices/android@2x.png"),
                                  },
                                }),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ]),
              ]),
            ]),
          ]
        ),
        _vm._v(" "),
        _vm._m(1),
        _vm._v(" "),
        _c("app-wrap", { attrs: { sectionClass: "bg-alt" } }, [
          _c("div", { staticClass: "copy" }, [
            _c("h2", [_vm._v("Easy-to-use apps for all your devices")]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "Just open the TunnelBear app, select a country, and flip the switch. Once you're connected, TunnelBear will work quietly in the background to keep your data secure."
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "hidden-xs" },
              [
                _c("div", { staticClass: "app-list" }, [
                  _c("div", { staticClass: "app" }, [
                    _c("img", {
                      attrs: { src: require("./images/apps/mac.svg"), alt: "" },
                    }),
                    _vm._v(" "),
                    _c("p", { staticClass: "fine-print-lg" }, [_vm._v("Mac")]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "app" }, [
                    _c("img", {
                      attrs: {
                        src: require("./images/apps/windows.svg"),
                        alt: "",
                      },
                    }),
                    _vm._v(" "),
                    _c("p", { staticClass: "fine-print-lg" }, [
                      _vm._v("Windows"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "app" }, [
                    _c("img", {
                      attrs: { src: require("./images/apps/ios.svg"), alt: "" },
                    }),
                    _vm._v(" "),
                    _c("p", { staticClass: "fine-print-lg" }, [_vm._v("iOS")]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "app" }, [
                    _c("img", {
                      attrs: {
                        src: require("./images/apps/android.svg"),
                        alt: "",
                      },
                    }),
                    _vm._v(" "),
                    _c("p", { staticClass: "fine-print-lg" }, [
                      _vm._v("Android"),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "router-link",
                  {
                    staticClass: "btn btn-lg",
                    attrs: { to: { name: "pricing" } },
                  },
                  [_vm._v("Get TunnelBear now")]
                ),
              ],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "section",
          { staticClass: "bg-light tb-opt-1", attrs: { id: "content-list" } },
          [
            _c("div", { staticClass: "section-inner" }, [
              _c("div", { staticClass: "container" }, [
                _c("h2", { staticClass: "text-center" }, [
                  _vm._v("Why millions of people are using TunnelBear"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "content" }, [
                  _c("div", { staticClass: "copy" }, [
                    _c(
                      "div",
                      { staticClass: "img-text" },
                      [
                        _c("img-set", {
                          attrs: {
                            lazyload: "",
                            classes: "mobile-btn app-store",
                            imgSrc: require("@/views/home/images/feature-wifi.svg"),
                          },
                        }),
                        _vm._v(" "),
                        _vm._m(2),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "img-text" },
                      [
                        _c("img-set", {
                          attrs: {
                            lazyload: "",
                            webpSrc: require("./images/feature-everywhere.webp"),
                            webpSrc2x: require("./images/feature-everywhere@2x.webp"),
                            imgSrc: require("./images/feature-everywhere.png"),
                            imgSrc2x: require("./images/feature-everywhere@2x.png"),
                          },
                        }),
                        _vm._v(" "),
                        _vm._m(3),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "img-text" },
                      [
                        _c("img-set", {
                          attrs: {
                            lazyload: "",
                            imgSrc: require("@/views/home/images/feature-bypass.svg"),
                          },
                        }),
                        _vm._v(" "),
                        _vm._m(4),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "copy" }, [
                    _c(
                      "div",
                      { staticClass: "img-text" },
                      [
                        _c("img-set", {
                          attrs: {
                            lazyload: "",
                            imgSrc: require("@/views/home/images/feature-surveillance.svg"),
                          },
                        }),
                        _vm._v(" "),
                        _vm._m(5),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "img-text" },
                      [
                        _c("img-set", {
                          attrs: {
                            lazyload: "",
                            imgSrc: require("@/views/home/images/feature-private.svg"),
                          },
                        }),
                        _vm._v(" "),
                        _vm._m(6),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "img-text" },
                      [
                        _c("img-set", {
                          attrs: {
                            lazyload: "",
                            imgSrc: require("@/views/home/images/feature-more.svg"),
                          },
                        }),
                        _vm._v(" "),
                        _vm._m(7),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("country-access", { attrs: { bg: "bg-space" } }),
        _vm._v(" "),
        _c("section", { staticClass: "bg-light", attrs: { id: "private" } }, [
          _c("div", { staticClass: "section-inner" }, [
            _c("div", { staticClass: "container" }, [
              _c("h2", { staticClass: "text-center" }, [
                _vm._v("Everything a VPN should have (and more)"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "content" }, [
                _c(
                  "div",
                  { staticClass: "img-holder" },
                  [
                    _c("img-set", {
                      attrs: {
                        lazyload: "",
                        imgSrc: require("@/views/home/images/protect-bear.svg"),
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "copy" }, [
                  _c(
                    "div",
                    { staticClass: "img-text" },
                    [
                      _c("img-set", {
                        attrs: {
                          lazyload: "",
                          imgSrc: require("@/views/home/images/feature-speed.svg"),
                        },
                      }),
                      _vm._v(" "),
                      _vm._m(8),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "img-text" },
                    [
                      _c("img-set", {
                        attrs: {
                          lazyload: "",
                          imgSrc: require("@/views/home/images/feature-audit.svg"),
                        },
                      }),
                      _vm._v(" "),
                      _vm._m(9),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "img-text" },
                    [
                      _c("img-set", {
                        attrs: {
                          lazyload: "",
                          imgSrc: require("@/views/home/images/feature-strong.svg"),
                        },
                      }),
                      _vm._v(" "),
                      _vm._m(10),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("section", { staticClass: "bg-regular", attrs: { id: "audit" } }, [
          _c("div", { staticClass: "section-inner" }, [
            _c("div", { staticClass: "container" }, [
              _c("div", { staticClass: "content" }, [
                _c("div", { staticClass: "copy text-center" }, [
                  _c("div", { staticClass: "copy-inner" }, [
                    _c("h2", [
                      _vm._v("Why millions of people trust TunnelBear"),
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "TunnelBear respects your privacy. We will never monitor, log, or sell any of your browsing activity. As the only VPN in the industry to perform annual, independent "
                      ),
                      _c("a", { attrs: { href: this.external.audit } }, [
                        _vm._v("security audits"),
                      ]),
                      _vm._v(
                        ", you can trust us to keep your connection secure."
                      ),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "img-holder" },
                  [
                    _c("img-set", {
                      attrs: {
                        lazyload: "",
                        imgSrc: require("@/views/home/images/audit-bear.svg"),
                        alt: "Independently Audited Code",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("section", { staticClass: "bg-light", attrs: { id: "FAQ" } }, [
          _c("div", { staticClass: "section-inner" }, [
            _c("div", { staticClass: "container" }, [
              _c("div", { staticClass: "content" }, [
                _c("h2", { staticClass: "text-center" }, [
                  _vm._v("Frequently Asked Questions"),
                ]),
                _vm._v(" "),
                _c("ul", { staticClass: "faq" }, [
                  _c(
                    "li",
                    [
                      _c(
                        "list-item",
                        [
                          _c("template", { slot: "title" }, [
                            _vm._v("What is TunnelBear and how does it work?"),
                          ]),
                          _vm._v(" "),
                          _c("template", { slot: "content" }, [
                            _c("p", [
                              _vm._v(
                                "TunnelBear is a virtual private network (or VPN for short)."
                              ),
                            ]),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(
                                "When you turn it on, all your device's incoming and outgoing data gets routed through a secure server which protects it with Grizzly-grade encryption."
                              ),
                            ]),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(
                                "That means when an internet service provider, network owner, or even hacker tries to snoop on your browsing activity, all they'll see is garbled, unreadable junk."
                              ),
                            ]),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(
                                "This process also changes your IP address (it's kind of like your internet phone number) to stop anyone online from seeing your real physical location or tying your activity back to you directly. "
                              ),
                            ]),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    [
                      _c(
                        "list-item",
                        [
                          _c("template", { slot: "title" }, [
                            _vm._v("Is my online privacy worth the cost?"),
                          ]),
                          _vm._v(" "),
                          _c("template", { slot: "content" }, [
                            _c("p", [
                              _vm._v(
                                "The value of online privacy depends on how comfortable you are with companies knowing "
                              ),
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: "https://news.vice.com/en_ca/article/j5dkmd/how-much-is-is-your-personal-data-really-worth",
                                    target: "_blank",
                                  },
                                },
                                [_vm._v("intimate details about your life")]
                              ),
                              _vm._v(
                                ". Often, they buy that information directly from your internet service provider (ISP), who can legally "
                              ),
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: "https://www.washingtonpost.com/news/the-switch/wp/2017/03/29/what-to-expect-now-that-internet-providers-can-collect-and-sell-your-web-browser-history/?noredirect=on&utm_term=.3c623097509a",
                                    target: "_blank",
                                  },
                                },
                                [
                                  _vm._v(
                                    "package and sell details about everything you do online"
                                  ),
                                ]
                              ),
                              _vm._v("."),
                            ]),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(
                                "The really troubling part is that once that information has been packaged and sold, it will most likely get sold again, moving from database to database for different purposes. Eventually, it could even end up being "
                              ),
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: "https://www.forbes.com/sites/thomasbrewster/2017/03/30/fcc-privacy-rules-how-isps-will-actually-sell-your-data/#92c0f7421d17",
                                    target: "_blank",
                                  },
                                },
                                [_vm._v("leaked into the ad-tech ecosystem")]
                              ),
                              _vm._v(
                                " where there's very little oversight for how your information is used, stored or protected."
                              ),
                            ]),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(
                                "Ultimately, privacy is a personal thing. Some people care a lot, while others don't care at all. For those that do, TunnelBear is a simple, affordable way to stop more of your data from being collected and sold in the future."
                              ),
                            ]),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    [
                      _c(
                        "list-item",
                        [
                          _c("template", { slot: "title" }, [
                            _vm._v(
                              "How can I confirm TunnelBear is keeping me secure?"
                            ),
                          ]),
                          _vm._v(" "),
                          _c("template", { slot: "content" }, [
                            _c("p", [
                              _vm._v(
                                "After connecting to a TunnelBear server (by selecting a country and flipping the switch in the app), visit "
                              ),
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: "https://bearsmyip.com/",
                                    target: "_blank",
                                  },
                                },
                                [_vm._v("bearsmyip.com")]
                              ),
                              _vm._v(
                                " to confirm that you appear in the country you selected. If it's a match, you're secure! As long as you don't disconnect from TunnelBear, all of your online activity will be encrypted and appear as though it's coming from a computer in the country you selected."
                              ),
                            ]),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    [
                      _c(
                        "list-item",
                        [
                          _c("template", { slot: "title" }, [
                            _vm._v(
                              "Will TunnelBear slow or change my browsing experience?"
                            ),
                          ]),
                          _vm._v(" "),
                          _c("template", { slot: "content" }, [
                            _c("p", [
                              _vm._v(
                                "TunnelBear shouldn't change your browsing experience unless you're tunnelling to a country that's really far away. For example, if you connect to a country halfway around the world, your browsing has to travel long distances before it reaches you. That can make pages load slower than usual, so unless you need to access something specific, select Fastest in TunnelBear's country selector to connect to the fastest available server."
                              ),
                            ]),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(
                                "If you're regularly slowed down by your internet service provider when watching videos or playing games, TunnelBear can actually speed up your connection by not allowing them to see what you're doing (and slow you down if they don't like it)."
                              ),
                            ]),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    [
                      _c(
                        "list-item",
                        [
                          _c("template", { slot: "title" }, [
                            _vm._v(
                              "How many devices can I use on one TunnelBear account?"
                            ),
                          ]),
                          _vm._v(" "),
                          _c("template", { slot: "content" }, [
                            _c(
                              "p",
                              [
                                _vm._v(
                                  "There is no limit on the number of devices you can use with TunnelBear. Simply visit "
                                ),
                                _c(
                                  "router-link",
                                  { attrs: { to: { name: "download" } } },
                                  [_vm._v("tunnelbear.com/download")]
                                ),
                                _vm._v(
                                  " on each device to install the TunnelBear app. Once the app is installed on your new device, log in with your existing TunnelBear account and connect to a server."
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    [
                      _c(
                        "list-item",
                        [
                          _c("template", { slot: "title" }, [
                            _vm._v(
                              "Why should I use TunnelBear over other VPNs?"
                            ),
                          ]),
                          _vm._v(" "),
                          _c("template", { slot: "content" }, [
                            _c("ul", [
                              _c("li", [
                                _c("p", [
                                  _vm._v(
                                    "We are the first consumer VPN to perform a third party security audit and "
                                  ),
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href:
                                          _vm.ENV.blogURL +
                                          "/tunnelbear_public_security_audit/",
                                        target: "_Blank",
                                      },
                                    },
                                    [_vm._v("publish the results")]
                                  ),
                                  _vm._v(
                                    ", something we are committed to doing "
                                  ),
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: this.external.audit,
                                        target: "_Blank",
                                      },
                                    },
                                    [_vm._v(" every year")]
                                  ),
                                  _vm._v("."),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("li", [
                                _c("p", [
                                  _vm._v(
                                    "We let you review and delete your own account data "
                                  ),
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: "https://help.tunnelbear.com/hc/en-us/articles/360006798512",
                                        target: "_Blank",
                                      },
                                    },
                                    [_vm._v("directly from our website")]
                                  ),
                                  _vm._v(" (most other VPNs don't)."),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("li", [
                                _c(
                                  "p",
                                  [
                                    _vm._v(
                                      "Unlike other providers, you don't need to be a lawyer to understand our "
                                    ),
                                    _c(
                                      "router-link",
                                      {
                                        attrs: {
                                          to: { name: "privacy-policy" },
                                        },
                                      },
                                      [_vm._v("Privacy Policy")]
                                    ),
                                    _vm._v("."),
                                  ],
                                  1
                                ),
                              ]),
                              _vm._v(" "),
                              _c("li", [
                                _c("p", [
                                  _vm._v(
                                    "We've received over 175,000 combined 5-star ratings on different app stores."
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("li", [
                                _c("p", [
                                  _vm._v(
                                    "TunnelBear apps are really easy to use—one on/off switch, no tech stuff."
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("li", [
                                _c(
                                  "p",
                                  [
                                    _vm._v("We let you "),
                                    _c(
                                      "router-link",
                                      { attrs: { to: { name: "pricing" } } },
                                      [_vm._v("try before you buy")]
                                    ),
                                    _vm._v(" with 2GB of free monthly data."),
                                  ],
                                  1
                                ),
                              ]),
                              _vm._v(" "),
                              _c("li", [
                                _c("p", [_vm._v("Bears are super adorable.")]),
                              ]),
                            ]),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("section", { staticClass: "bg-alt", attrs: { id: "download" } }, [
          _c("div", { staticClass: "section-inner" }, [
            _c("div", { staticClass: "container text-center" }, [
              _c(
                "div",
                { staticClass: "element copy centered" },
                [
                  _c("h2", [
                    _vm._v(
                      "Download TunnelBear to start browsing privately today!"
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.is.ios()
                    ? _c(
                        "a",
                        { attrs: { href: _vm.external.downloadLinks.ios } },
                        [
                          _c("img-set", {
                            attrs: {
                              lazyload: "",
                              classes: "mobile-btn app-store",
                              imgSrc: require("@/images/app-store.svg"),
                              alt: "Download on the App Store",
                            },
                          }),
                        ],
                        1
                      )
                    : _c(
                        "router-link",
                        {
                          staticClass: "btn btn-lg",
                          attrs: { to: { name: "pricing" } },
                        },
                        [_vm._v("Get TunnelBear now")]
                      ),
                ],
                1
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        !_vm.isAiBlockedCountry ? _c("ai-chat-bot") : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "fine-print-lg mtop-10 bold" }, [
      _vm._v("\n                    Your first year of TunnelBear for "),
      _c("span", { staticClass: "strike", attrs: { "aria-hidden": "true" } }, [
        _vm._v("$119USD"),
      ]),
      _vm._v(" "),
      _c("strong", [_vm._v("$39.99USD")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "section",
      { staticClass: "bg-light", attrs: { id: "recommended" } },
      [
        _c("div", { staticClass: "section-inner" }, [
          _c("div", { staticClass: "element large centered content" }, [
            _c("img", {
              attrs: {
                src: require("./images/logos/forbes.svg"),
                alt: "Forbes",
              },
            }),
            _vm._v(" "),
            _c("img", {
              attrs: {
                src: require("./images/logos/verge.svg"),
                alt: "the Verge",
              },
            }),
            _vm._v(" "),
            _c("img", {
              attrs: {
                srcset:
                  require("./images/logos/lifehacker.png") +
                  " 1x, " +
                  require("./images/logos/lifehacker@2x.png") +
                  " 2x",
                src: require("./images/logos/lifehacker.png"),
                alt: "LifeHacker",
              },
            }),
            _vm._v(" "),
            _c("img", {
              attrs: { src: require("./images/logos/tnw.svg"), alt: "TNW" },
            }),
            _vm._v(" "),
            _c("img", {
              attrs: { src: require("./images/logos/vb.svg"), alt: "VB" },
            }),
            _vm._v(" "),
            _c("img", {
              attrs: {
                src: require("./images/logos/techradar.svg"),
                alt: "TechRadar",
              },
            }),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("h3", { staticClass: "h6" }, [_vm._v("Stop password and data theft")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "Hackers can steal passwords and data over insecure public WiFi. TunnelBear blocks them to keep you secure."
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("h3", { staticClass: "h6" }, [_vm._v("Access global content")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "Some content is only available in certain regions. TunnelBear changes your virtual location so you can see it anywhere."
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("h3", { staticClass: "h6" }, [_vm._v("Bypass local censorship")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "Some governments block popular websites and apps. TunnelBear unblocks them by changing your virtual location."
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("h3", { staticClass: "h6" }, [_vm._v("Protect your online privacy")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "Network owners and internet providers can see everything you do online. With TunnelBear on, they can't see a thing."
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("h3", { staticClass: "h6" }, [_vm._v("Prevent IP-based tracking")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "Ad services use your IP address to track your behaviour across sites. TunnelBear stops them by assigning you a new IP."
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("h3", { staticClass: "h6" }, [_vm._v("And way, way more")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "Play on new game servers, prevent speed throttling, and unblock apps and websites on school and work networks."
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("h3", { staticClass: "h6" }, [_vm._v("Engineered for speed")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "Our global server network is optimized to let you surf and stream quickly. No throttling, no buffering, no fuss."
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("h3", { staticClass: "h6" }, [_vm._v("Security you can rely on")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "TunnelBear is the only VPN in the world to publish regular, independent security audits of our apps."
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("h3", { staticClass: "h6" }, [_vm._v("Strong Encryption")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "TunnelBear uses strong AES 256-bit encryption by default. Weaker encryption isn't even an option."
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }