var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.portalType === "login"
      ? _c("div", [
          _c(
            "div",
            { staticClass: "copy" },
            [
              _vm._t("login-copy", function () {
                return [
                  _c("h2", [_vm._v("Log In")]),
                  _vm._v(" "),
                  _vm.reset
                    ? _c("p", { staticClass: "alert alert-success" }, [
                        _vm._v("Your password reset email has been sent!"),
                      ])
                    : _c("p", { attrs: { id: _vm.labelID } }, [
                        _vm._v("Sign in to your account"),
                      ]),
                ]
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "form-wrap", class: _vm.size },
            [
              _c("login-form", {
                attrs: { emailPlaceholder: _vm.emailPlaceholder },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.forgotLink
            ? _c(
                "p",
                { staticClass: "fine-print-lg" },
                [
                  _vm._v("I forgot "),
                  _c("router-link", { attrs: { to: { name: "forgot" } } }, [
                    _vm._v(" my password"),
                  ]),
                  _vm._v("."),
                ],
                1
              )
            : _c("p", { staticClass: "fine-print-lg" }, [
                _vm._v("I forgot "),
                _c(
                  "button",
                  {
                    staticClass: "btn plain link",
                    on: {
                      click: function ($event) {
                        _vm.currentFace = "forgot"
                      },
                    },
                  },
                  [_vm._v(" my password")]
                ),
                _vm._v("."),
              ]),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.hideSignup,
                  expression: "!hideSignup",
                },
              ],
            },
            [
              _vm.portalType === "login"
                ? _c("p", { staticClass: "mtop-20" }, [
                    _vm._v("Don't have a TunnelBear account? "),
                    _c(
                      "button",
                      {
                        staticClass: "plain link",
                        on: {
                          click: function ($event) {
                            _vm.currentFace = "signup"
                          },
                        },
                      },
                      [_vm._v("Sign up")]
                    ),
                  ])
                : _c(
                    "p",
                    { staticClass: "mtop-20" },
                    [
                      _vm._v("Don't have a TunnelBear account? "),
                      _c(
                        "router-link",
                        {
                          staticClass: "plain link",
                          attrs: {
                            to: { name: "signup", query: _vm.$route.query },
                          },
                        },
                        [_vm._v("Sign up")]
                      ),
                    ],
                    1
                  ),
            ]
          ),
        ])
      : _vm.portalType === "forgot"
      ? _c("div", [
          _c(
            "div",
            { staticClass: "copy" },
            [
              _vm._t("forgot-copy", function () {
                return [
                  _c("h2", [_vm._v("Password reset")]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "Enter your email address below and we'll send you a link to reset your password."
                    ),
                  ]),
                ]
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "form-wrap", class: _vm.size },
            [
              _c("forgot-form", { on: { success: _vm.successReset } }),
              _vm._v(" "),
              _c("p", { staticClass: "mtop-20" }, [
                _vm._v("Take me "),
                _c(
                  "button",
                  {
                    staticClass: "btn plain link",
                    on: {
                      click: function ($event) {
                        _vm.currentFace = "login"
                      },
                    },
                  },
                  [_vm._v("back to Login")]
                ),
                _vm._v("."),
              ]),
            ],
            1
          ),
        ])
      : _vm.portalType === "captcha"
      ? _c("div", [
          _c(
            "div",
            { staticClass: "copy" },
            [
              _vm._t("captcha-copy", function () {
                return [
                  _c("h2", [_vm._v("Almost done…")]),
                  _vm._v(" "),
                  _c("p", { attrs: { id: _vm.labelID } }, [
                    _vm._v("Bear with us!"),
                  ]),
                ]
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "form-wrap", class: _vm.size },
            [
              _c("captcha-form", {
                ref: "captchaForm",
                on: {
                  success: _vm.successReset,
                  getCaptchaTokenFromCaptchaForm:
                    _vm.emitCaptchaTokenToPaymentCharge,
                },
              }),
            ],
            1
          ),
        ])
      : _c("div", [
          _c(
            "div",
            { staticClass: "copy" },
            [
              _vm._t("signup-copy", function () {
                return [
                  _c("h2", [_vm._v("You're almost there")]),
                  _vm._v(" "),
                  _c("p", { attrs: { id: _vm.labelID } }, [
                    _vm._v("Sign up to get TunnelBear"),
                  ]),
                ]
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "form-wrap", class: _vm.size },
            [_c("signup-form")],
            1
          ),
          _vm._v(" "),
          _vm.portalType
            ? _c("p", { staticClass: "mtop-10" }, [
                _vm._v("Or "),
                _c(
                  "button",
                  {
                    staticClass: "plain link",
                    attrs: { "data-cy": "btn--login-existing" },
                    on: {
                      click: function ($event) {
                        _vm.currentFace = "login"
                      },
                    },
                  },
                  [_vm._v("use an existing account")]
                ),
              ])
            : _c(
                "p",
                { staticClass: "mtop-20" },
                [
                  _vm._v("Or "),
                  _c(
                    "router-link",
                    {
                      staticClass: "plain link",
                      attrs: { to: { name: "login", query: _vm.$route.query } },
                    },
                    [_vm._v("use an existing account")]
                  ),
                ],
                1
              ),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }