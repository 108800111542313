var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "view-wrap page-element bg-dark",
      attrs: { id: "footer", inert: _vm.modalOpen },
    },
    [
      _c("div", { staticClass: "view-content" }, [
        !_vm.minimal
          ? _c("section", { staticClass: "smaller" }, [
              _c("div", { staticClass: "section-inner" }, [
                _c("div", { staticClass: "container" }, [
                  !_vm.isTbMcAfeeLandingPage
                    ? _c("div", { staticClass: "footer-content" }, [
                        _c("div", { staticClass: "footer-column" }, [
                          _c("h6", [_vm._v("TunnelBear")]),
                          _vm._v(" "),
                          _c("ul", [
                            _c(
                              "li",
                              [
                                _c(
                                  "router-link",
                                  { attrs: { to: { name: "what-is-vpn" } } },
                                  [_vm._v("What is a VPN?")]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "li",
                              [
                                _c(
                                  "router-link",
                                  { attrs: { to: { name: "download" } } },
                                  [_vm._v("Download")]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "li",
                              [
                                _c(
                                  "router-link",
                                  { attrs: { to: { name: "features" } } },
                                  [_vm._v("Features")]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "li",
                              [
                                _c(
                                  "router-link",
                                  { attrs: { to: { name: "pricing" } } },
                                  [_vm._v("Pricing")]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "li",
                              [
                                _c(
                                  "router-link",
                                  { attrs: { to: { name: "teams" } } },
                                  [_vm._v("TunnelBear for Teams")]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("li", [
                              _c("a", { attrs: { href: _vm.external.help } }, [
                                _vm._v("Help"),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "li",
                              [
                                _c(
                                  "router-link",
                                  { attrs: { to: { name: "overview" } } },
                                  [_vm._v("My Account")]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "footer-column" }, [
                          _c("h6", [_vm._v("Apps")]),
                          _vm._v(" "),
                          _c("ul", [
                            _c(
                              "li",
                              [
                                _c(
                                  "router-link",
                                  { attrs: { to: { name: "ios" } } },
                                  [_vm._v("iPhone & iPad")]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "li",
                              [
                                _c(
                                  "router-link",
                                  { attrs: { to: { name: "android" } } },
                                  [_vm._v("Android")]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "li",
                              [
                                _c(
                                  "router-link",
                                  { attrs: { to: { name: "mac" } } },
                                  [_vm._v("Mac")]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "li",
                              [
                                _c(
                                  "router-link",
                                  { attrs: { to: { name: "windows" } } },
                                  [_vm._v("Windows")]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "li",
                              [
                                _c(
                                  "router-link",
                                  { attrs: { to: { name: "browser" } } },
                                  [_vm._v("Browser")]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "li",
                              [
                                _c(
                                  "router-link",
                                  { attrs: { to: { name: "blocker" } } },
                                  [_vm._v("Blocker")]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "footer-column" }, [
                          _c("h6", [_vm._v("Company")]),
                          _vm._v(" "),
                          _c("ul", [
                            _c("li", [
                              _c("a", { attrs: { href: _vm.ENV.blogURL } }, [
                                _vm._v("Blog"),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "li",
                              [
                                _c(
                                  "router-link",
                                  { attrs: { to: { name: "about" } } },
                                  [_vm._v("About Us")]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "li",
                              [
                                _c(
                                  "router-link",
                                  { attrs: { to: { name: "jobs-index" } } },
                                  [_vm._v("Jobs")]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "li",
                              [
                                _c(
                                  "router-link",
                                  { attrs: { to: { name: "affiliate" } } },
                                  [_vm._v("Affiliate")]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "li",
                              [
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: { name: "internet-freedom-hub" },
                                    },
                                  },
                                  [_vm._v("Internet Freedom Hub")]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "li",
                              [
                                _c(
                                  "router-link",
                                  { attrs: { to: { name: "privacy" } } },
                                  [_vm._v("Privacy Center")]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "li",
                              [
                                _c(
                                  "router-link",
                                  { attrs: { to: { name: "privacy-policy" } } },
                                  [_vm._v("Privacy Policy")]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "li",
                              [
                                _c(
                                  "router-link",
                                  {
                                    attrs: { to: { name: "terms-of-service" } },
                                  },
                                  [_vm._v("Terms of Service")]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "footer-column" }, [
                          _c("h6", [_vm._v("Follow Us")]),
                          _vm._v(" "),
                          _c("ul", { staticClass: "horizontal" }, [
                            _c("li", [
                              _c(
                                "a",
                                {
                                  staticClass: "icon-parent",
                                  attrs: { href: _vm.external.facebookUrl },
                                },
                                [_vm._m(0)]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("li", [
                              _c(
                                "a",
                                {
                                  staticClass: "icon-parent",
                                  attrs: { href: _vm.external.instagramUrl },
                                },
                                [_vm._m(1)]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("li", [
                              _c(
                                "a",
                                {
                                  staticClass: "icon-parent",
                                  attrs: { href: _vm.external.twitterUrl },
                                },
                                [_vm._m(2)]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("li", [
                              _c(
                                "a",
                                {
                                  staticClass: "icon-parent",
                                  attrs: { href: _vm.external.telegramUrl },
                                },
                                [_vm._m(3)]
                              ),
                            ]),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.isTbMcAfeeLandingPage
                    ? _c(
                        "div",
                        {
                          staticClass: "text-center",
                          attrs: { id: "essentials" },
                        },
                        [
                          _c("h6", [_vm._v("Get the Bear Essentials")]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "apps" },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "dark",
                                  attrs: { to: { name: "download" } },
                                },
                                [
                                  _c("div", { staticClass: "app" }, [
                                    _c("img", {
                                      attrs: {
                                        src: require("./images/tunnelbear-app.svg"),
                                        alt: "TunnelBear",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("span", [_vm._v("TunnelBear")]),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isTbMcAfeeLandingPage
                    ? _c("div", { staticClass: "text-center" }, [
                        _vm.isTbMcAfeeLandingPage
                          ? _c("img", {
                              staticClass: "mbottom-20",
                              attrs: {
                                src: require("@/images/tb-mcafee-logo-grey.svg"),
                                alt: "TunnelBear",
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c("p", [
                          _vm._v("©" + _vm._s(_vm.year) + " TunnelBear LLC."),
                        ]),
                        _vm._v(" "),
                        _c("p", [
                          _vm._v("Grizzly regards from Toronto, Canada"),
                        ]),
                      ])
                    : _c(
                        "div",
                        {
                          staticClass: "text-center",
                          attrs: { id: "signature" },
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: require("@/images/logo-grey.svg"),
                              alt: "TunnelBear",
                            },
                          }),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v("©" + _vm._s(_vm.year) + " TunnelBear LLC."),
                          ]),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v("Grizzly regards from Toronto, Canada"),
                          ]),
                        ]
                      ),
                ]),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.minimal
          ? _c("div", { staticClass: "view-wrap minimal" }, [
              _c("div", { staticClass: "section-inner" }, [
                _c("div", { staticClass: "container text-center" }, [
                  _c("p", { staticClass: "fine-print-lg" }, [
                    _vm._v("©" + _vm._s(_vm.year) + " TunnelBear LLC."),
                  ]),
                ]),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.pi ? _c("div", { staticClass: "pi-video" }) : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "pi",
            on: {
              click: function ($event) {
                return _vm.piClick($event)
              },
            },
          },
          [_vm._v("π")]
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("i", { staticClass: "icon icon-facebook" }, [
      _c("span", [_vm._v("Facebook")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("i", { staticClass: "icon icon-instagram" }, [
      _c("span", [_vm._v("Instagram")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("i", { staticClass: "icon icon-twitter" }, [
      _c("span", [_vm._v("Twitter")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("i", { staticClass: "icon icon-telegram" }, [
      _c("span", [_vm._v("Telegram")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }