var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: [
        _vm.layout.bgColor ? "bg-takeover " + _vm.layout.bgColor : "bg-dark",
        { "no-scroll": _vm.noScroll },
      ],
      attrs: { id: "app", v_cloak: "" },
    },
    [
      _c(
        "div",
        {
          staticClass: "app-content",
          on: {
            "~click": function ($event) {
              return _vm.activeUser()
            },
          },
        },
        [
          !_vm.layout.hideNav
            ? _c("nav-bar", {
                attrs: {
                  minimal: _vm.layout.minimalNav,
                  hideCtaButton: _vm.layout.hideCtaButton,
                  customCta: _vm.layout.customCta,
                },
                on: {
                  toggleScroll: function ($event) {
                    _vm.noScroll = $event
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("router-view", { on: { modifyLayout: _vm.modifyLayout } }),
          _vm._v(" "),
          !_vm.layout.hideFooter
            ? _c("site-footer", {
                attrs: { minimal: _vm.layout.minimalFooter },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("cookie-banner", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showCookieBanner,
            expression: "showCookieBanner",
          },
        ],
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }