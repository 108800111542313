var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { class: _vm.sectionClass, attrs: { id: "apps" } }, [
    _c("div", { staticClass: "section-inner content" }, [
      _c(
        "div",
        {
          staticClass: "img-holder",
          class: _vm.is.mac() || _vm.is.ios() ? "apple" : "other",
        },
        [
          _vm.imgs[0]
            ? _c("img", {
                staticClass: "laptop",
                attrs: { src: _vm.imgs[0], alt: "" },
              })
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "element xs centered text-center" },
        [_vm._t("default")],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "img-holder",
          class: _vm.is.mac() || _vm.is.ios() ? "apple" : "other",
        },
        [
          _vm.imgs[2]
            ? _c("img", {
                staticClass: "mobile",
                attrs: { src: _vm.imgs[2], alt: "" },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.imgs[1]
            ? _c("img", {
                staticClass: "tablet",
                attrs: { src: _vm.imgs[1], alt: "" },
              })
            : _vm._e(),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }