import { render, staticRenderFns } from "./flags.vue?vue&type=template&id=20c04f9f&scoped=true"
import script from "./flags.vue?vue&type=script&lang=js"
export * from "./flags.vue?vue&type=script&lang=js"
import style0 from "./flags.styl?vue&type=style&index=0&id=20c04f9f&scoped=true&lang=stylus&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20c04f9f",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/build/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('20c04f9f')) {
      api.createRecord('20c04f9f', component.options)
    } else {
      api.reload('20c04f9f', component.options)
    }
    module.hot.accept("./flags.vue?vue&type=template&id=20c04f9f&scoped=true", function () {
      api.rerender('20c04f9f', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/flags/flags.vue"
export default component.exports