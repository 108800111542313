var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("iframe", {
      attrs: {
        id: "captcha-form",
        src: _vm.iframeURL,
        title: "Prove you're a human (sadly necessary 😢)",
      },
    }),
    _vm._v(" "),
    _vm.captchaError !== null || _vm.showTimeoutMessage
      ? _c(
          "p",
          [
            _vm.captchaError === "bad-message"
              ? _c("span", [
                  _vm._v(
                    "\n      Oh no, looks like couldn't understand a system message!\n    "
                  ),
                ])
              : _vm.captchaError === "missing-token"
              ? _c("span", [
                  _vm._v(
                    "\n      Uh oh, looks like we lost a system message!\n    "
                  ),
                ])
              : _vm.captchaError !== null
              ? _c("span", [
                  _vm._v(
                    "\n      Sorry, looks like something unexpected went wrong!\n    "
                  ),
                ])
              : _vm.showTimeoutMessage
              ? _c("span", [_vm._v("\n      Having trouble?\n    ")])
              : _vm._e(),
            _vm._v(
              "\n    Our friendly support bears are always available through the "
            ),
            _c("router-link", { attrs: { to: { name: "support" } } }, [
              _vm._v("support form"),
            ]),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }