var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("announce", [
        _vm.submitError
          ? _c(
              "p",
              {
                staticClass: "alert alert-danger",
                attrs: { "data-cy": "error--submit" },
              },
              [_vm._v(_vm._s(_vm.submitError))]
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c(
        "form",
        {
          staticClass: "form-area auth",
          attrs: { name: "singupForm", id: "signup-form", novalidate: "" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.submit.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "form-row" },
            [
              _c("announce", [
                _c(
                  "p",
                  {
                    staticClass: "field-error",
                    class: { "show-error": _vm.errors.current === "email" },
                    attrs: { "data-cy": "error--email" },
                  },
                  [_vm._v("Grr... That is not a valid email")]
                ),
              ]),
              _vm._v(" "),
              _c(
                "dynamic-input",
                {
                  attrs: { name: "email", type: "email", required: "" },
                  on: { model: _vm.emailTyping, focus: _vm.emailFocus },
                },
                [_vm._v("Email")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "form-row" },
            [
              _c("announce", [
                _c(
                  "p",
                  {
                    staticClass: "field-error",
                    class: { "show-error": _vm.errors.current === "password" },
                    attrs: { "data-cy": "error--password" },
                  },
                  [
                    _vm._v(
                      "Grr... Your password must be at least 8 characters long"
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c(
                "dynamic-input",
                {
                  attrs: { name: "password", type: "password", required: "" },
                  on: {
                    model: function ($event) {
                      _vm.formData.password = $event
                    },
                    blur: _vm.passwordBlur,
                    focus: _vm.passwordFocus,
                  },
                },
                [_vm._v("Password")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.showAgeCheckbox
            ? _c(
                "div",
                { staticClass: "form-row" },
                [
                  _c("announce", [
                    _c(
                      "p",
                      {
                        staticClass: "field-error",
                        class: {
                          "show-error": _vm.errors.current === "ageCheck",
                        },
                        attrs: { "data-cy": "error--age" },
                      },
                      [_vm._v("Please confirm your age")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "age-consent-wrapper" }, [
                    _c(
                      "label",
                      {
                        staticClass: "checkbox",
                        attrs: { for: "age_consent" },
                      },
                      [
                        _c("input", {
                          attrs: {
                            type: "checkbox",
                            id: "age_consent",
                            name: "age",
                          },
                          on: {
                            model: function ($event) {
                              _vm.formData.ageCheck = $event
                            },
                            change: function ($event) {
                              return _vm.onAgeCheckboxChange($event)
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "check" }),
                        _vm._v(
                          "\n          I am 14 years of age or older\n        "
                        ),
                      ]
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "submit-button",
            {
              attrs: { finished: _vm.finished, submitting: _vm.submitting },
              on: { submit: _vm.submit },
            },
            [_vm._v("Create Account")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }