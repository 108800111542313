import { render, staticRenderFns } from "./nav-links-dropdown.vue?vue&type=template&id=2657d052"
import script from "./nav-links-dropdown.vue?vue&type=script&lang=js"
export * from "./nav-links-dropdown.vue?vue&type=script&lang=js"
import style0 from "./nav-links-dropdown.styl?vue&type=style&index=0&lang=stylus&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/build/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2657d052')) {
      api.createRecord('2657d052', component.options)
    } else {
      api.reload('2657d052', component.options)
    }
    module.hot.accept("./nav-links-dropdown.vue?vue&type=template&id=2657d052", function () {
      api.rerender('2657d052', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/nav/components/nav-links-dropdown/nav-links-dropdown.vue"
export default component.exports