var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isSvg
    ? _c("img", {
        class: [_vm.classes, _vm.lazyloaded ? "lazyloaded" : undefined],
        attrs: {
          "data-cy": _vm.hasDimensions ? "image--svg-lazyload" : undefined,
          src: _vm.source,
          alt: _vm.alt,
        },
      })
    : _c("picture", [
        _vm.webpSourceSet
          ? _c("source", {
              attrs: { srcset: _vm.webpSourceSet, type: "image/webp" },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.sourceSet && !_vm.hasDimensions
          ? _c("source", { attrs: { srcset: _vm.sourceSet } })
          : _vm._e(),
        _vm._v(" "),
        _c("img", {
          ref: "image",
          class: [_vm.classes, _vm.lazyloaded ? "lazyloaded" : undefined],
          attrs: {
            "data-cy": _vm.hasDimensions ? "image--lazyload" : undefined,
            src: _vm.source,
            alt: _vm.alt,
            sizes: _vm.hasDimensions ? _vm.imgWidth : undefined,
            srcset: _vm.hasDimensions ? _vm.sourceSet : undefined,
          },
        }),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }