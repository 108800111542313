var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "testimony" }, [
    _c("img", { attrs: { src: require("./images/wirecutter.svg"), alt: "" } }),
    _vm._v(" "),
    _c("blockquote", { staticClass: "text-center" }, [
      _c(
        "p",
        { staticClass: "quote" },
        [
          _vm._t("default", function () {
            return [
              _vm._v(
                "TunnelBear exceeded our expectations for trust and transparency, the most important factors to consider when you’re choosing the best VPN service."
              ),
            ]
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c("p", [_vm._v("- Wirecutter (A New York Times Company)")]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }