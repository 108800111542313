var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "input-wrap" }, [
    _vm.type !== "select"
      ? _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model.trim",
              value: _vm.model,
              expression: "model",
              modifiers: { trim: true },
            },
          ],
          attrs: {
            name: _vm.name,
            id: _vm.name,
            type: _vm.type,
            required: _vm.required,
            "data-cy": _vm.name + "-input",
            autocomplete: _vm.autocomplete,
          },
          domProps: { value: _vm.model },
          on: {
            focus: function ($event) {
              return _vm.focus()
            },
            blur: [
              function ($event) {
                return _vm.blur()
              },
              function ($event) {
                return _vm.$forceUpdate()
              },
            ],
            input: function ($event) {
              if ($event.target.composing) return
              _vm.model = $event.target.value.trim()
            },
          },
        })
      : _c("div", { staticClass: "select-wrap" }, [
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.model,
                  expression: "model",
                },
              ],
              class: { selected: _vm.model },
              attrs: {
                name: _vm.name,
                id: _vm.name,
                type: _vm.type,
                required: _vm.required,
                "data-cy": _vm.name + "-select",
              },
              on: {
                focus: function ($event) {
                  _vm.focused = true
                },
                change: function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.model = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                },
              },
            },
            [_vm._t("options")],
            2
          ),
          _vm._v(" "),
          _c("span", { staticClass: "caret" }),
        ]),
    _vm._v(" "),
    _c(
      "label",
      { class: { active: _vm.active }, attrs: { for: _vm.name } },
      [_vm._t("default")],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }