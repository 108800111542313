var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "view-wrap" },
    [
      _c(
        "modal",
        { ref: "modal", attrs: { labelID: "portal-desc" } },
        [
          _c("portal", {
            attrs: { type: _vm.modalType, labelID: "portal-desc" },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "view-content", attrs: { inert: _vm.modalOpen } },
        [
          _vm.is.mobile() || _vm.is.tablet()
            ? [
                _c(
                  "section",
                  { staticClass: "bg-light", attrs: { id: "mobile-hero" } },
                  [
                    _c("div", { staticClass: "section-inner" }, [
                      _c("div", { staticClass: "container text-center" }, [
                        _c("h1", { staticClass: "h2" }, [
                          _vm._v("Download TunnelBear"),
                        ]),
                        _vm._v(" "),
                        _vm.is.ios()
                          ? _c("img", {
                              staticClass: "mobile",
                              attrs: {
                                src: require("./images/iphone@2x.png"),
                                alt: "",
                              },
                            })
                          : _c("img", {
                              staticClass: "mobile",
                              attrs: {
                                src: require("./images/android@2x.png"),
                                alt: "",
                              },
                            }),
                      ]),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c("section", { attrs: { id: "mobile-content" } }, [
                  _c("div", { staticClass: "section-inner" }, [
                    _c("div", { staticClass: "container text-center" }, [
                      _vm.is.ios()
                        ? _c("div", { staticClass: "store" }, [
                            _c(
                              "a",
                              {
                                attrs: { href: _vm.external.downloadLinks.ios },
                              },
                              [
                                _c("img", {
                                  staticClass: "app-store",
                                  attrs: {
                                    src: require("@/images/app-store.svg"),
                                    alt: "Download on the App Store",
                                  },
                                }),
                              ]
                            ),
                            _vm._v(" "),
                            _c("p", { staticClass: "fine-print" }, [
                              _vm._v(
                                "iPads and iPhones with " +
                                  _vm._s(this.apps.ios.osSupport)
                              ),
                            ]),
                          ])
                        : _c("div", { staticClass: "store" }, [
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: _vm.external.downloadLinks.android,
                                },
                              },
                              [
                                _c("img", {
                                  staticClass: "google-play",
                                  attrs: {
                                    src: require("@/images/google-play.svg"),
                                    alt: "Get it on Google Play",
                                  },
                                }),
                              ]
                            ),
                            _vm._v(" "),
                            _c("p", { staticClass: "fine-print" }, [
                              _vm._v(
                                "Android  " +
                                  _vm._s(this.apps.android.osSupport)
                              ),
                            ]),
                          ]),
                      _vm._v(" "),
                      _c(
                        "p",
                        [
                          _vm._v("Psst... You can get a TunnelBear for "),
                          _c(
                            "router-link",
                            { attrs: { to: { name: "download-devices" } } },
                            [_vm._v("all your devices")]
                          ),
                          _vm._v("!"),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("img", {
                        staticClass: "devices",
                        attrs: {
                          src: require("./images/home_device_icons2@2x.png"),
                          alt: "Available for Android, iOS, Apple, Windows, Chrome browser.",
                        },
                      }),
                    ]),
                  ]),
                ]),
              ]
            : [
                _c(
                  "section",
                  { staticClass: "bg-light", attrs: { id: "hero" } },
                  [
                    _c("div", { staticClass: "section-inner" }, [
                      _c("div", { staticClass: "container vertical-center" }, [
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-sm-12 col-md-7 vertical-center text-center",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "copy",
                                  attrs: { "data-cy": "copy--downloading" },
                                },
                                [
                                  _vm.autodownload
                                    ? [
                                        _c("h1", [
                                          _vm._v(
                                            "Downloading TunnelBear for " +
                                              _vm._s(_vm.deviceName)
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("p", [
                                          _vm._v(
                                            "Your TunnelBear download should start automatically."
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("p", [
                                          _vm._v("If it doesn't, "),
                                          _c(
                                            "button",
                                            {
                                              staticClass: "btn plain link",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.download()
                                                },
                                              },
                                            },
                                            [_vm._v("restart the download")]
                                          ),
                                          _vm._v("."),
                                        ]),
                                      ]
                                    : [
                                        _c("h1", [
                                          _vm._v("Download TunnelBear"),
                                        ]),
                                        _vm._v(" "),
                                        !_vm.autodownload
                                          ? _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn btn-lg submit-btn",
                                                attrs: {
                                                  "data-cy": "btn--download",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.download()
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "Download for " +
                                                    _vm._s(_vm.deviceName)
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                  _vm._v(" "),
                                  _c(
                                    "p",
                                    { staticClass: "fine-print os-version" },
                                    [_vm._v(_vm._s(this.supportedMinOSVersion))]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "p",
                                    {
                                      staticClass: "different-device",
                                      class: {
                                        "fine-print-lg": _vm.autodownload,
                                      },
                                      attrs: {
                                        "data-cy": "copy--different-device",
                                      },
                                    },
                                    [
                                      _vm._v("Or get TunnelBear for a "),
                                      _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: { name: "download-devices" },
                                          },
                                        },
                                        [_vm._v("different device")]
                                      ),
                                      _vm._v("."),
                                    ],
                                    1
                                  ),
                                ],
                                2
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-sm-12 col-md-5 img" }, [
                            _vm.autodownload || _vm.triggeredDownload
                              ? _c("img", {
                                  staticClass: "dl-bear",
                                  attrs: {
                                    srcset:
                                      require("./images/download_bear.gif") +
                                      " 1x, " +
                                      require("./images/download_bear@2x.gif") +
                                      " 2x",
                                    src: require("./images/download_bear@2x.gif"),
                                    alt: "",
                                  },
                                })
                              : _c("img", {
                                  staticClass: "dl-bear",
                                  attrs: {
                                    srcset:
                                      require("./images/download_bear_pause.png") +
                                      " 1x, " +
                                      require("./images/download_bear_pause@2x.png") +
                                      " 2x",
                                    src: require("./images/download_bear_pause@2x.png"),
                                    alt: "",
                                  },
                                }),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c("section", { attrs: { id: "instructions" } }, [
                  _c("div", { staticClass: "section-inner" }, [
                    _c("div", { staticClass: "container text-center" }, [
                      _c("div", { staticClass: "steps" }, [
                        _vm.is.mac()
                          ? _c("div", { staticClass: "step" }, [
                              _c("img", {
                                attrs: {
                                  srcset:
                                    require("./images/download-open.png") +
                                    " 1x, " +
                                    require("./images/download-open@2x.png") +
                                    " 2x",
                                  src: require("./images/download-open@2x.png"),
                                  alt: "TunnelBear zip file",
                                },
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "circle-num" }, [
                                _vm._v("1"),
                              ]),
                              _vm._v(" "),
                              _c("p", { staticClass: "h6" }, [
                                _vm._v("Open the file"),
                              ]),
                              _vm._v(" "),
                              _c("p", [
                                _vm._v(
                                  "Double-click the TunnelBear Zip file you downloaded"
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "step" },
                          [
                            _c("img", {
                              attrs: {
                                srcset:
                                  require("./images/download-click.png") +
                                  " 1x, " +
                                  require("./images/download-click@2x.png") +
                                  " 2x",
                                src: require("./images/download-click@2x.png"),
                                alt: "TunnelBear Application Icon",
                              },
                            }),
                            _vm._v(" "),
                            _vm.is.mac()
                              ? [
                                  _c("span", { staticClass: "circle-num" }, [
                                    _vm._v("2"),
                                  ]),
                                  _vm._v(" "),
                                  _c("p", { staticClass: "h6" }, [
                                    _vm._v("Launch the app"),
                                  ]),
                                  _vm._v(" "),
                                  _c("p", [
                                    _vm._v(
                                      'Double-click the TunnelBear icon and select "Move to Applications Folder"'
                                    ),
                                  ]),
                                ]
                              : [
                                  _c("span", { staticClass: "circle-num" }, [
                                    _vm._v("1"),
                                  ]),
                                  _vm._v(" "),
                                  _c("p", { staticClass: "h6" }, [
                                    _vm._v("Install the app"),
                                  ]),
                                  _vm._v(" "),
                                  _c("p", [
                                    _vm._v(
                                      "Double-click the new TunnelBear icon and follow the on-screen instructions"
                                    ),
                                  ]),
                                ],
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "step" }, [
                          _c("img", {
                            attrs: {
                              srcset:
                                require("./images/download-login.png") +
                                " 1x, " +
                                require("./images/download-login@2x.png") +
                                " 2x",
                              src: require("./images/download-login@2x.png"),
                              alt: "Email input, Password input, Log in button",
                            },
                          }),
                          _vm._v(" "),
                          _vm.is.mac()
                            ? _c("span", { staticClass: "circle-num" }, [
                                _vm._v("3"),
                              ])
                            : _c("span", { staticClass: "circle-num" }, [
                                _vm._v("2"),
                              ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "h6" }, [
                            _vm._v("Sign up or log in"),
                          ]),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v(
                              "Follow the on-screen instructions to log in or create an account"
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "step" }, [
                          _c("img", {
                            attrs: {
                              srcset:
                                require("./images/download-toggle.png") +
                                " 1x, " +
                                require("./images/download-toggle@2x.png") +
                                " 2x",
                              src: require("./images/download-toggle@2x.png"),
                              alt: "Toggle switch",
                            },
                          }),
                          _vm._v(" "),
                          _vm.is.mac()
                            ? _c("span", { staticClass: "circle-num" }, [
                                _vm._v("4"),
                              ])
                            : _c("span", { staticClass: "circle-num" }, [
                                _vm._v("3"),
                              ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "h6" }, [
                            _vm._v("Start tunneling"),
                          ]),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v(
                              "Pick a country or select Fastest for the fastest speeds, then toggle the switch ON!"
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
              ],
          _vm._v(" "),
          _c("section", { attrs: { id: "subnotes" } }, [
            _c("div", { staticClass: "section-inner" }, [
              _c("div", { staticClass: "container" }, [
                _c(
                  "div",
                  { staticClass: "notices" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "fine-print",
                        attrs: { to: { name: "legal-notices" } },
                      },
                      [_vm._v("Notice and Attribution")]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "fine-print",
                        attrs: {
                          href: "https://help.tunnelbear.com/hc/en-us/articles/360007120951",
                        },
                      },
                      [_vm._v("Uninstall TunnelBear")]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "p",
                  { staticClass: "fine-print terms-policy-text" },
                  [
                    _vm._v("\n              By downloading "),
                    _c("strong", [_vm._v("TunnelBear")]),
                    _vm._v(",\n              you agree to the "),
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: { name: "terms-of-service" },
                          target: "_blank",
                        },
                      },
                      [_vm._v("Terms of Service")]
                    ),
                    _vm._v("\n              and "),
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: { name: "privacy-policy" },
                          target: "_blank",
                        },
                      },
                      [_vm._v("Privacy Policy")]
                    ),
                    _vm._v("."),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("trigger-download", { ref: "download" }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }