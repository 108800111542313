var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container", class: { expanded: _vm.expanded } },
    [
      _c(
        "button",
        {
          staticClass: "faq-title btn plain dark",
          on: {
            click: function ($event) {
              _vm.expanded = !_vm.expanded
            },
          },
        },
        [
          _vm._t("title"),
          _vm._v(" "),
          _c("img", {
            class: { expanded: _vm.expanded },
            attrs: {
              src: require("./images/plus.png"),
              alt: _vm.expanded ? "minimize list item" : "expand list tem",
            },
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c("transition", { attrs: { name: "slide-fade" } }, [
        _vm.expanded
          ? _c("div", { staticClass: "faq-content" }, [_vm._t("content")], 2)
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }