var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "counter",
      class: {
        "counter-block container element element-medium": _vm.styleBlock,
      },
    },
    [
      _c("div", { staticClass: "timer", class: { row: _vm.styleBlock } }, [
        _vm.yearsToTarget
          ? _c("div", { staticClass: "col" }, [
              _c("p", { staticClass: "number" }, [
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isSingleDigit(_vm.yearsToTarget),
                        expression: "isSingleDigit(yearsToTarget)",
                      },
                    ],
                  },
                  [_vm._v("0")]
                ),
                _vm._v(_vm._s(_vm.yearsToTarget)),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v("year"),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isPlural(_vm.yearsToTarget),
                        expression: "isPlural(yearsToTarget)",
                      },
                    ],
                  },
                  [_vm._v("s")]
                ),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.daysToTarget
          ? _c("div", { staticClass: "col" }, [
              _c("p", { staticClass: "number" }, [
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isSingleDigit(_vm.daysToTarget),
                        expression: "isSingleDigit(daysToTarget)",
                      },
                    ],
                  },
                  [_vm._v("0")]
                ),
                _vm._v(_vm._s(_vm.daysToTarget)),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v("day"),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isPlural(_vm.daysToTarget),
                        expression: "isPlural(daysToTarget)",
                      },
                    ],
                  },
                  [_vm._v("s")]
                ),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "col" }, [
          _c("p", { staticClass: "number" }, [
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isSingleDigit(_vm.hoursToTarget),
                    expression: "isSingleDigit(hoursToTarget)",
                  },
                ],
              },
              [_vm._v("0")]
            ),
            _vm._v(_vm._s(_vm.hoursToTarget)),
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v("hour"),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isPlural(_vm.hoursToTarget),
                    expression: "isPlural(hoursToTarget)",
                  },
                ],
              },
              [_vm._v("s")]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col" }, [
          _c("p", { staticClass: "number" }, [
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isSingleDigit(_vm.minsToTarget),
                    expression: "isSingleDigit(minsToTarget)",
                  },
                ],
              },
              [_vm._v("0")]
            ),
            _vm._v(_vm._s(_vm.minsToTarget)),
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v("min"),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isPlural(_vm.minsToTarget),
                    expression: "isPlural(minsToTarget)",
                  },
                ],
              },
              [_vm._v("s")]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col" }, [
          _c("p", { staticClass: "number" }, [
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isSingleDigit(_vm.secsToTarget),
                    expression: "isSingleDigit(secsToTarget)",
                  },
                ],
              },
              [_vm._v("0")]
            ),
            _vm._v(_vm._s(_vm.secsToTarget)),
          ]),
          _vm._v(" "),
          _c("p", [_vm._v("secs")]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }