var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "view-wrap" }, [
    _c("div", { staticClass: "view-content" }, [
      !_vm.isSanctioned && !_vm.isCountryIndia
        ? _c("section", { staticClass: "top-section smaller" }, [
            _c("div", { staticClass: "section-inner" }, [
              _c("h1", { staticClass: "text-center" }, [
                _vm._v("Pick the plan that's right for you"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "mobile-menu" }, [
                _c(
                  "button",
                  {
                    class: { selected: _vm.mobileSelect === "free" },
                    on: {
                      click: function ($event) {
                        _vm.mobileSelect = "free"
                      },
                    },
                  },
                  [_vm._v("Free")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    class: { selected: _vm.mobileSelect === "unlimited" },
                    on: {
                      click: function ($event) {
                        _vm.mobileSelect = "unlimited"
                      },
                    },
                  },
                  [_vm._v("Unlimited")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    class: { selected: _vm.mobileSelect === "teams" },
                    on: {
                      click: function ($event) {
                        _vm.mobileSelect = "teams"
                      },
                    },
                  },
                  [_vm._v("Teams")]
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "section",
        {
          class: { "free-instead": _vm.isSanctioned || _vm.isCountryIndia },
          attrs: { id: "bear-plans" },
        },
        [
          _c(
            "div",
            { staticClass: "section-inner" },
            [
              !_vm.isSanctioned && !_vm.isCountryIndia
                ? _c(
                    "div",
                    {
                      staticClass: "plans",
                      class: "select-" + _vm.mobileSelect,
                    },
                    [
                      _c("div", { staticClass: "item-free item-column" }, [
                        _vm._m(0),
                        _vm._v(" "),
                        _c("div", { staticClass: "inner-wrap" }, [
                          _c("div", { staticClass: "inner" }, [
                            _c(
                              "div",
                              { staticClass: "content" },
                              [
                                _c("p", [_vm._v("Testing and limited usage")]),
                                _vm._v(" "),
                                _vm._m(1),
                                _vm._v(" "),
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "btn btn-lg",
                                    attrs: {
                                      "aria-label":
                                        "Download TunnelBear for Free with a data limit of 2GB per month",
                                      to: { name: "download" },
                                    },
                                  },
                                  [_vm._v("Try for free")]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "item-unlimited item-column" }, [
                        _c("div", { staticClass: "bear-pic" }, [
                          _c(
                            "div",
                            { staticClass: "bear-img-wrap unlimited" },
                            [
                              _vm.sale.isLive && _vm.sale.name === "winter-sale"
                                ? _c("img", {
                                    attrs: {
                                      src: require("./images/bear-yearly-winter.svg"),
                                      alt: "",
                                    },
                                  })
                                : _vm.sale.isLive &&
                                  _vm.sale.name === "spring-sale"
                                ? _c("img", {
                                    attrs: {
                                      src: require("./images/bear-yearly-spring.svg"),
                                      alt: "spring-sale-bear",
                                    },
                                  })
                                : _vm.sale.isLive &&
                                  _vm.sale.name === "summer-sale"
                                ? _c("img", {
                                    staticClass: "summer-sale",
                                    attrs: {
                                      src: require("./images/bear-yearly-summer.svg"),
                                      alt: "summer-sale-bear",
                                    },
                                  })
                                : _c("img", {
                                    attrs: {
                                      srcset:
                                        require("./images/bear-yearly.png") +
                                        " 1x, " +
                                        require("./images/bear-yearly@2x.png") +
                                        " 2x",
                                      src: require("./images/bear-yearly@2x.png"),
                                      alt: "",
                                    },
                                  }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "h2",
                            {
                              class: {
                                h2: true,
                                "spring-sale":
                                  _vm.sale.isLive &&
                                  _vm.sale.name === "spring-sale",
                              },
                            },
                            [_vm._v("Unlimited")]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "inner-wrap" }, [
                          _c("div", { staticClass: "inner" }, [
                            _c(
                              "div",
                              { staticClass: "content" },
                              [
                                _c("p", [
                                  _vm._v("All-day security and peace of mind"),
                                ]),
                                _vm._v(" "),
                                _vm._m(2),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    class: {
                                      pricing: true,
                                      seasonal:
                                        _vm.sale.isLive &&
                                        _vm.sale.name === "seasonal",
                                    },
                                  },
                                  [
                                    _c("p", [
                                      _vm._v("from "),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "amount",
                                          class: {
                                            "green-text":
                                              _vm.sale.isLive &&
                                              _vm.sale.name === "spring-sale",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "$" +
                                              _vm._s(
                                                _vm.pricingValues[
                                                  _vm.currentProduct
                                                ].saleMonthlyPrice ||
                                                  _vm.pricingValues[
                                                    _vm.currentProduct
                                                  ].monthlyPrice
                                              )
                                          ),
                                        ]
                                      ),
                                      _vm._v("/mo"),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "btn btn-lg",
                                    class: {
                                      "btn-decor":
                                        _vm.sale.isLive &&
                                        _vm.sale.name === "holiday",
                                    },
                                    attrs: {
                                      "aria-label":
                                        "Get Unlimited TunnelBear for $" +
                                        _vm.pricingValues[_vm.currentProduct]
                                          .billingPrice +
                                        "USD per year",
                                      to: _vm.unlimitedUrl,
                                    },
                                  },
                                  [_vm._v("Get started")]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "item-teams item-column" }, [
                        _vm._m(3),
                        _vm._v(" "),
                        _c("div", { staticClass: "inner-wrap" }, [
                          _c("div", { staticClass: "inner" }, [
                            _c(
                              "div",
                              { staticClass: "content" },
                              [
                                _c("p", [
                                  _vm._v("For businesses securing 2+ users"),
                                ]),
                                _vm._v(" "),
                                _vm._m(4),
                                _vm._v(" "),
                                _c("div", { staticClass: "pricing" }, [
                                  _c("p", [
                                    _c("span", { staticClass: "amount" }, [
                                      _vm._v(
                                        "$" +
                                          _vm._s(
                                            _vm.pricingValues
                                              .TB4T_YEARLY_PAYMENT.monthlyPrice
                                          )
                                      ),
                                    ]),
                                    _vm._v("/user per month"),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "btn btn-lg",
                                    attrs: {
                                      "aria-label":
                                        "Get TunnelBear for Teams for $" +
                                        _vm.pricingValues.TB4T_YEARLY_PAYMENT
                                          .monthlyPrice +
                                        "USD per month",
                                      to: { name: "teams" },
                                    },
                                  },
                                  [_vm._v("Get started")]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.isSanctioned && !_vm.isCountryIndia
                ? _c("div", { staticClass: "payment-info" }, [
                    _c("img", {
                      staticClass: "payment-img",
                      attrs: {
                        src: require("./images/payment-options.svg"),
                        alt: "Acceptable payment types include Visa, Mastercard, American Express, Jars of Honey",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "p",
                      { staticClass: "fine-print payment-note col-xs-12" },
                      [_vm._v("All pricing shown in USD")]
                    ),
                  ])
                : _vm.isCountryIndia
                ? _c(
                    "div",
                    {
                      staticClass:
                        "payment-info free-instead country-india-container",
                    },
                    [
                      _c("div", { staticClass: "item-free item-column" }, [
                        _vm._m(5),
                        _vm._v(" "),
                        _c("div", { staticClass: "inner-wrap" }, [
                          _c("div", { staticClass: "inner" }, [
                            _c("div", { staticClass: "content" }, [
                              _vm._m(6),
                              _vm._v(" "),
                              _vm._m(7),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  staticClass: "btn btn-lg read-more-btn",
                                  attrs: {
                                    href: _vm.ReadMoreCertIndia,
                                    target: "_blank",
                                  },
                                },
                                [_vm._v("Read more")]
                              ),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]
                  )
                : _c("div", { staticClass: "payment-info free-instead" }, [
                    _c("div", { staticClass: "item-free item-column" }, [
                      _c("div", { staticClass: "bear-pic" }, [
                        _c(
                          "div",
                          { staticClass: "bear-img-wrap stop-censorship" },
                          [
                            _vm.countryCode == "RU"
                              ? _c("img", {
                                  staticClass: "stop-censorship-sign",
                                  attrs: {
                                    src: require("./images/stop-censorship-ru.png"),
                                    alt: "'Stop censorship' on a sign surrounded by Russian flags, held up by a bear paw",
                                  },
                                })
                              : _vm.countryCode == "BY"
                              ? _c("img", {
                                  staticClass: "stop-censorship-sign",
                                  attrs: {
                                    src: require("./images/stop-censorship-by.png"),
                                    alt: "'Stop censorship' on a sign surrounded by Belarusian flags, held up by a bear paw",
                                  },
                                })
                              : _c("img", {
                                  staticClass: "stop-censorship-sign",
                                  attrs: {
                                    src: require("./images/stop-censorship.png"),
                                    alt: "'Stop censorship' on a sign held up by a bear paw",
                                  },
                                }),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "inner-wrap" }, [
                        _c("div", { staticClass: "inner" }, [
                          _c(
                            "div",
                            { staticClass: "content" },
                            [
                              _c("h2", { staticClass: "h2" }, [
                                _vm.countryCode == "RU"
                                  ? _c("span", [
                                      _vm._v(
                                        "TunnelBear is free to use from Russia"
                                      ),
                                    ])
                                  : _vm.countryCode == "BY"
                                  ? _c("span", [
                                      _vm._v(
                                        "TunnelBear is free to use from Belarus"
                                      ),
                                    ])
                                  : _c("span", [
                                      _vm._v(
                                        "TunnelBear cannot be purchased from your country"
                                      ),
                                    ]),
                              ]),
                              _vm._v(" "),
                              _vm.countryCode == "RU"
                                ? _c("p", [
                                    _vm._v(
                                      "In order to ensure protest organizers, journalists, and at-risk individuals have access to a safe and informed internet, we've opened up our network to anyone connecting from Russia."
                                    ),
                                  ])
                                : _vm.countryCode == "BY"
                                ? _c("p", [
                                    _vm._v(
                                      "In order to ensure protest organizers, journalists, and at-risk individuals have access to a safe and informed internet, we've opened up our network to anyone connecting from Belarus."
                                    ),
                                  ])
                                : _c("p", [
                                    _vm._v(
                                      "You can still use a free account with limited bandwidth."
                                    ),
                                  ]),
                              _vm._v(" "),
                              !_vm.isCountryIndia
                                ? _c(
                                    "ul",
                                    { staticClass: "check-list yellow copy" },
                                    [
                                      ["RU", "BY"].includes(_vm.countryCode)
                                        ? _c("li", [
                                            _vm._v("10GB of secure browsing"),
                                          ])
                                        : _c("li", [
                                            _vm._v("2GB of secure browsing"),
                                          ]),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "router-link",
                                {
                                  staticClass: "btn btn-lg",
                                  attrs: {
                                    "aria-label":
                                      "Download TunnelBear for Free with a data limit of 2GB per month",
                                    to: { name: "download" },
                                  },
                                },
                                [_vm._v("Download")]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
              _vm._v(" "),
              _c("wirecutter", { staticClass: "wirecutter" }, [
                _vm._v(
                  "\n          We spent more than 65 hours researching 53 VPN services [and] TunnelBear is the most transparent and trustworthy provider offering fast, secure connections and easy setup.\n        "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "faq-section" }, [
                _c("div", { staticClass: "container" }, [
                  _c("h2", [_vm._v("Frequently Asked Questions")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "faqs" }, [
                    _vm._m(8),
                    _vm._v(" "),
                    _vm._m(9),
                    _vm._v(" "),
                    _vm._m(10),
                    _vm._v(" "),
                    _vm._m(11),
                    _vm._v(" "),
                    _c("div", { staticClass: "faq" }, [
                      _c("h6", [
                        _vm._v(
                          "How many devices can I use on one TunnelBear account?"
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "p",
                        [
                          _vm._v(
                            "There is no limit on the number of devices you can use with TunnelBear. Simply visit "
                          ),
                          _c(
                            "router-link",
                            { attrs: { to: { name: "download" } } },
                            [_vm._v("tunnelbear.com/download")]
                          ),
                          _vm._v(
                            " on each device to install the TunnelBear app. Once the app is installed on your new device, log in with your existing TunnelBear account and connect to a server."
                          ),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "faq" }, [
                      _c("h6", [
                        _vm._v("Why should I use TunnelBear over other VPNs?"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "p",
                        [
                          _vm._v(
                            "Lots of reasons! It's incredibly simple to use, we're the only consumer VPN provider to perform annual security audits ("
                          ),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: this.external.audit,
                                target: "_blank",
                              },
                            },
                            [_vm._v("and publish the results")]
                          ),
                          _vm._v(
                            "), you don't need a lawyer to understand our "
                          ),
                          _c(
                            "router-link",
                            { attrs: { to: { name: "privacy-policy" } } },
                            [_vm._v("privacy policy")]
                          ),
                          _vm._v(
                            ", and we've got over 175,000 5-star reviews!"
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ],
            1
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "bear-pic" }, [
      _c("div", { staticClass: "bear-img-wrap free" }, [
        _c("img", {
          staticClass: "bear large",
          attrs: { src: require("./images/bear-free.svg"), alt: "" },
        }),
        _vm._v(" "),
        _c("img", {
          staticClass: "bear small",
          attrs: { src: require("./images/mobile-free.svg"), alt: "" },
        }),
      ]),
      _vm._v(" "),
      _c("h2", { staticClass: "h2" }, [_vm._v("Free")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", { staticClass: "check-list yellow copy" }, [
      _c("li", [_vm._v("2GB of secure browsing")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", { staticClass: "check-list copy" }, [
      _c("li", [_vm._v("Unlimited secure browsing")]),
      _vm._v(" "),
      _c("li", [_vm._v("Unlimited devices")]),
      _vm._v(" "),
      _c("li", [_vm._v("Premium VPN servers")]),
      _vm._v(" "),
      _c("li", [_vm._v("City-level server selection")]),
      _vm._v(" "),
      _c("li", [_vm._v("Priority customer support")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "bear-pic" }, [
      _c("div", { staticClass: "bear-img-wrap teams" }, [
        _c("img", {
          attrs: { src: require("./images/bear-teams.svg"), alt: "" },
        }),
      ]),
      _vm._v(" "),
      _c("h2", { staticClass: "h2" }, [_vm._v("Teams")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", { staticClass: "check-list copy" }, [
      _c("li", [_vm._v("Unlimited secure browsing")]),
      _vm._v(" "),
      _c("li", [_vm._v("Unlimited devices")]),
      _vm._v(" "),
      _c("li", [_vm._v("Premium VPN servers")]),
      _vm._v(" "),
      _c("li", [_vm._v("City-level server selection")]),
      _vm._v(" "),
      _c("li", [_vm._v("Priority customer support")]),
      _vm._v(" "),
      _c("li", [_vm._v("Centralized team billing")]),
      _vm._v(" "),
      _c("li", [_vm._v("Admin & account manager tools")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "bear-pic" }, [
      _c("div", { staticClass: "bear-img-wrap stop-censorship" }, [
        _c("img", {
          staticClass: "stop-censorship-sign",
          attrs: {
            src: require("./images/stop-india.png"),
            alt: "TunnelBear no longer available in India",
          },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h2", { staticClass: "h2" }, [
      _c("span", [_vm._v("TunnelBear no longer available in India")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v("Due to the recent "),
      _c(
        "a",
        {
          attrs: {
            href: "https://www.cert-in.org.in/PDF/CERT-In_Directions_70B_28.04.2022.pdf",
            target: "_blank",
          },
        },
        [_vm._v(" CERT-In regulations")]
      ),
      _vm._v(
        ", TunnelBear is no longer available to purchase for users in India. "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "faq" }, [
      _c("h6", [_vm._v("How does TunnelBear work?")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "TunnelBear encrypts your device's incoming and outgoing data. That means when an internet service provider, network owner, or even hacker tries to snoop on your online activity, all they'll see is garbled, unreadable junk."
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "faq" }, [
      _c("h6", [_vm._v("Is my online privacy worth the cost?")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "Some internet service providers can legally package and sell details about everything you do online, so it really depends on how comfortable you are with your personal data being made available to others. "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "faq" }, [
      _c("h6", [_vm._v("How do I know TunnelBear is working?")]),
      _vm._v(" "),
      _c("p", [
        _vm._v("With TunnelBear ON, visit "),
        _c(
          "a",
          { attrs: { href: "https://bearsmyip.com", target: "_blank" } },
          [_vm._v("bearsmyip.com")]
        ),
        _vm._v(
          " to confirm you're secure. As long as you don't disconnect, your online activity will be encrypted and appear as though it's coming from someone else's computer."
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "faq" }, [
      _c("h6", [_vm._v("Will TunnelBear slow my browsing?")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "Not unless you're tunnelling to a country that's really far away. If you don't need to access something from a specific country, set TunnelBear's country selector to Fastest to ensure the best performance."
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }