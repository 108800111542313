var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("transition", { attrs: { name: "fade" } }, [
        !_vm.accepted
          ? _c("div", { staticClass: "view-wrap" }, [
              _c("div", { staticClass: "view-content" }, [
                _c(
                  "section",
                  {
                    staticClass: "banner-wrap no-padding",
                    class: { visible: !_vm.accepted },
                  },
                  [
                    _c("div", { staticClass: "banner-inner section-inner" }, [
                      _c("div", { staticClass: "banner-content" }, [
                        _c("div", { staticClass: "text-img" }, [
                          _c("img", {
                            attrs: {
                              src: require("./images/cookies.svg"),
                              alt: "cookies",
                            },
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "txt" }, [
                            _c(
                              "p",
                              { staticClass: "small-copy" },
                              [
                                _vm._v(
                                  "\n                    Rawr! We use cookies to help operate our website and make your experience better. By continuing on our site, you're giving us consent to feed your browser cookies. "
                                ),
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: {
                                        name: "privacy-policy",
                                        query: _vm.$route.query,
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "Learn more about how we use cookies"
                                    ),
                                  ]
                                ),
                                _vm._v(".\n                  "),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                on: {
                                  "~click": function ($event) {
                                    return _vm.accept()
                                  },
                                },
                              },
                              [_vm._v("Got it")]
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]
                ),
              ]),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }