var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "view-wrap" },
    [
      _c(
        "modal",
        {
          ref: "modal",
          attrs: { closeable: true, size: "large", labelID: "countries-desc" },
        },
        [
          _c("h4", { staticClass: "h5", attrs: { id: "countries-desc" } }, [
            _vm._v("Servers in " + _vm._s(_vm.tunnels.length) + " countries"),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "countries-container" },
            [_c("country-flags")],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "view-content", attrs: { inert: _vm.modalOpen } },
        [
          _c("section", { staticClass: "bg-light", attrs: { id: "hero" } }, [
            _c("div", { staticClass: "section-inner" }, [
              _c("div", { staticClass: "container" }, [
                _c("h1", { staticClass: "text-center" }, [
                  _vm._v("\n            What is a VPN?\n            "),
                  _c("span", [_vm._v(_vm._s(_vm.year) + " Guide")]),
                ]),
                _vm._v(" "),
                _c("img", {
                  attrs: {
                    src: require("./images/bear-globe.svg"),
                    alt: "TunnelBear network spans the globe",
                  },
                }),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          _c(
            "section",
            { staticClass: "bg-regular smaller", attrs: { id: "contents" } },
            [
              _c("div", { staticClass: "section-inner" }, [
                _c("div", { staticClass: "container" }, [
                  _c("div", { staticClass: "element medium centered" }, [
                    _c("h2", { staticClass: "text-center" }, [
                      _vm._v("VPN Overview: Table of Contents"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "table" }, [
                      _c(
                        "a",
                        {
                          attrs: { href: "#what-is-a-vpn" },
                          on: {
                            click: function ($event) {
                              return _vm.scrollToHash("#what-is-a-vpn")
                            },
                          },
                        },
                        [_vm._v("What is a VPN?")]
                      ),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          attrs: { href: "#why-do-i-need" },
                          on: {
                            click: function ($event) {
                              return _vm.scrollToHash("#why-do-i-need")
                            },
                          },
                        },
                        [_vm._v("Why do I need a VPN?")]
                      ),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          attrs: { href: "#how-it-works" },
                          on: {
                            click: function ($event) {
                              return _vm.scrollToHash("#how-it-works")
                            },
                          },
                        },
                        [_vm._v("What does a VPN do and how does it work?")]
                      ),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          attrs: { href: "#vpn-client" },
                          on: {
                            click: function ($event) {
                              return _vm.scrollToHash("#vpn-client")
                            },
                          },
                        },
                        [_vm._v("What does a VPN client do exactly?")]
                      ),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          attrs: { href: "#how-to-install" },
                          on: {
                            click: function ($event) {
                              return _vm.scrollToHash("#how-to-install")
                            },
                          },
                        },
                        [_vm._v("How do I install a VPN?")]
                      ),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          attrs: { href: "#how-to-use" },
                          on: {
                            click: function ($event) {
                              return _vm.scrollToHash("#how-to-use")
                            },
                          },
                        },
                        [_vm._v("How do I use my VPN once it has been set up?")]
                      ),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          attrs: { href: "#vpn-faq" },
                          on: {
                            click: function ($event) {
                              return _vm.scrollToHash("#vpn-faq")
                            },
                          },
                        },
                        [_vm._v("VPN FAQ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          attrs: { href: "#why-tunnelbear" },
                          on: {
                            click: function ($event) {
                              return _vm.scrollToHash("#why-tunnelbear")
                            },
                          },
                        },
                        [
                          _vm._v(
                            "Why TunnelBear is the right VPN solution for you"
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "section",
            { staticClass: "bg-regular smaller", attrs: { id: "what" } },
            [
              _c("span", {
                staticClass: "anchor",
                attrs: { id: "what-is-a-vpn" },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "section-inner" }, [
                _c("div", { staticClass: "container" }, [
                  _vm._m(1),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass: "pull-quote text-center",
                      attrs: { "aria-hidden": "true" },
                    },
                    [
                      _vm._v(
                        "A VPN encrypts your internet connection to keep your online activity private on any network."
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("h3", { staticClass: "h4 text-center" }, [
                    _vm._v("When you connect to a VPN"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row list" }, [
                    _c(
                      "div",
                      { staticClass: "col col-12 col-md-4 text-center" },
                      [
                        _c(
                          "img-item",
                          [
                            _c("template", { slot: "img" }, [
                              _c("img", {
                                attrs: {
                                  src: require("./images/connect/browse.svg"),
                                  alt: "VPN blocks online spying",
                                },
                              }),
                            ]),
                            _vm._v(" "),
                            _c("template", { slot: "content" }, [
                              _c("p", [
                                _vm._v(
                                  "Your browsing is private, so you can't be easily tracked online"
                                ),
                              ]),
                            ]),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col col-12 col-md-4 text-center" },
                      [
                        _c(
                          "img-item",
                          [
                            _c("template", { slot: "img" }, [
                              _c("img", {
                                attrs: {
                                  src: require("./images/connect/unblock.svg"),
                                  alt: "Tunnel around the world",
                                },
                              }),
                            ]),
                            _vm._v(" "),
                            _c("template", { slot: "content" }, [
                              _c("p", [
                                _vm._v(
                                  "Your connection looks like it's coming from another country, which unblocks some websites"
                                ),
                              ]),
                            ]),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col col-12 col-md-4 text-center" },
                      [
                        _c(
                          "img-item",
                          [
                            _c("template", { slot: "img" }, [
                              _c("img", {
                                attrs: {
                                  src: require("./images/connect/encrypted.svg"),
                                  alt: "Encrypt your browsing",
                                },
                              }),
                            ]),
                            _vm._v(" "),
                            _c("template", { slot: "content" }, [
                              _c("p", [
                                _vm._v(
                                  "Your internet connection is encrypted, keeping the information you send and receive private"
                                ),
                              ]),
                            ]),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "section",
            { staticClass: "bg-regular smaller", attrs: { id: "why" } },
            [
              _c("span", {
                staticClass: "anchor",
                attrs: { id: "why-do-i-need" },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "section-inner" }, [
                _c("div", { staticClass: "container" }, [
                  _vm._m(2),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass: "pull-quote text-center",
                      attrs: { "aria-hidden": "true" },
                    },
                    [
                      _vm._v(
                        "You need a VPN because they provide security and protect your privacy."
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    { staticClass: "text-center" },
                    [
                      _vm._v("Ready to start using a VPN? "),
                      _c(
                        "router-link",
                        { attrs: { to: { name: "pricing" } } },
                        [_vm._v("Get TunnelBear today!")]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "section",
            { staticClass: "bg-regular smaller", attrs: { id: "reasons" } },
            [
              _c("div", { staticClass: "section-inner" }, [
                _c("div", { staticClass: "container" }, [
                  _c("h2", { staticClass: "text-center" }, [
                    _vm._v("Top reasons people use VPN"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row list" }, [
                    _c(
                      "div",
                      { staticClass: "col col-12 col-md-4" },
                      [
                        _c(
                          "list-box",
                          { attrs: { initialExpand: true } },
                          [
                            _c("template", { slot: "img" }, [
                              _c("img", {
                                attrs: {
                                  src: require("./images/reasons/ip.svg"),
                                  alt: "Bear knocking over street sign",
                                },
                              }),
                            ]),
                            _vm._v(" "),
                            _c("template", { slot: "title" }, [
                              _c("h3", { staticClass: "h5" }, [
                                _vm._v("Change your IP address"),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("template", { slot: "content" }, [
                              _c("p", [
                                _vm._v(
                                  "\n                    Your IP address is kind of like a phone number that tells websites where you are and how to connect to your device.\n                    TunnelBear gives you a new IP, so all of your traffic looks like it's coming from somewhere else.\n                  "
                                ),
                              ]),
                            ]),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col col-12 col-md-4" },
                      [
                        _c(
                          "list-box",
                          [
                            _c("template", { slot: "img" }, [
                              _c("img", {
                                attrs: {
                                  src: require("./images/reasons/tracking.svg"),
                                  alt: "Bear blocking path of search hound",
                                },
                              }),
                            ]),
                            _vm._v(" "),
                            _c("template", { slot: "title" }, [
                              _c("h3", { staticClass: "h5" }, [
                                _vm._v("Block online tracking"),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("template", { slot: "content" }, [
                              _c("p", [
                                _vm._v(
                                  "\n                    Advertisers invade your online privacy by targeting your IP for ads. By changing your IP address, TunnelBear blocks\n                    common ways advertising trackers follow you around the internet.\n                  "
                                ),
                              ]),
                            ]),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col col-12 col-md-4" },
                      [
                        _c(
                          "list-box",
                          [
                            _c("template", { slot: "img" }, [
                              _c("img", {
                                attrs: {
                                  src: require("./images/reasons/wifi.svg"),
                                  alt: "Bear protecting woman at coffee shop",
                                },
                              }),
                            ]),
                            _vm._v(" "),
                            _c("template", { slot: "title" }, [
                              _c("h3", { staticClass: "h5" }, [
                                _vm._v("Stay safe on public WiFi"),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("template", { slot: "content" }, [
                              _c("p", [
                                _vm._v(
                                  "\n                    People love public Wi-Fi, but so do hackers. With simple tools, they can capture passwords and logins as they pass\n                    through a shared Wi-Fi point. Keep your accounts safe by encrypting your connection with TunnelBear.\n                  "
                                ),
                              ]),
                            ]),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col col-12 col-md-4" },
                      [
                        _c(
                          "list-box",
                          [
                            _c("template", { slot: "img" }, [
                              _c("img", {
                                attrs: {
                                  src: require("./images/reasons/home.svg"),
                                  alt: "Bear tunneling across the world",
                                },
                              }),
                            ]),
                            _vm._v(" "),
                            _c("template", { slot: "title" }, [
                              _c("h3", { staticClass: "h5" }, [
                                _vm._v("Stay connected to home"),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("template", { slot: "content" }, [
                              _c("p", [
                                _vm._v(
                                  "\n                    Don't miss out on current events or local news when you're travelling. With servers in "
                                ),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn plain link",
                                    on: {
                                      click: function ($event) {
                                        return _vm.openModal()
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.tunnels.length) + " countries"
                                    ),
                                  ]
                                ),
                                _vm._v(
                                  ", TunnelBear\n                    can help you stay connected to home as if you were there.\n                  "
                                ),
                              ]),
                            ]),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col col-12 col-md-4" },
                      [
                        _c(
                          "list-box",
                          [
                            _c("template", { slot: "img" }, [
                              _c("img", {
                                attrs: {
                                  src: require("./images/reasons/stream.svg"),
                                  alt: "Spedometer showing speeds from turtle, to rabbit to grizzly bear",
                                },
                              }),
                            ]),
                            _vm._v(" "),
                            _c("template", { slot: "title" }, [
                              _c("h3", { staticClass: "h5" }, [
                                _vm._v("Stream video faster"),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("template", { slot: "content" }, [
                              _c("p", [
                                _vm._v(
                                  "\n                    Internet service providers regularly throttle services they compete with, but with TunnelBear, you can avoid throttling\n                    by keeping your browsing private from your service provider.\n                  "
                                ),
                              ]),
                            ]),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col col-12 col-md-4" },
                      [
                        _c(
                          "list-box",
                          [
                            _c("template", { slot: "img" }, [
                              _c("img", {
                                attrs: {
                                  src: require("./images/reasons/censorship.svg"),
                                  alt: "Bear escaping through a cage",
                                },
                              }),
                            ]),
                            _vm._v(" "),
                            _c("template", { slot: "title" }, [
                              _c("h3", { staticClass: "h5" }, [
                                _vm._v("Bypass censorship"),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("template", { slot: "content" }, [
                              _c("p", [
                                _vm._v(
                                  "\n                    More governments are trying to censor news and communications outside their borders. TunnelBear can help bypass censorship\n                    by connecting to a server in another country, so you can get the information you need.\n                  "
                                ),
                              ]),
                            ]),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col col-12 col-md-4" },
                      [
                        _c(
                          "list-box",
                          [
                            _c("template", { slot: "img" }, [
                              _c("img", {
                                attrs: {
                                  src: require("./images/reasons/travel.svg"),
                                  alt: "Bear protecting beach goers",
                                },
                              }),
                            ]),
                            _vm._v(" "),
                            _c("template", { slot: "title" }, [
                              _c("h3", { staticClass: "h5" }, [
                                _vm._v("Travel securely"),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("template", { slot: "content" }, [
                              _c("p", [
                                _vm._v(
                                  "\n                    Don't trust the Wi-Fi in your hotel? TunnelBear's Grizzly-grade encryption lets you safely check your email, bank account,\n                    book flights and more—all without risking your personal information.\n                  "
                                ),
                              ]),
                            ]),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col col-12 col-md-4" },
                      [
                        _c(
                          "list-box",
                          [
                            _c("template", { slot: "img" }, [
                              _c("img", {
                                attrs: {
                                  src: require("./images/reasons/privacy.svg"),
                                  alt: "Bear head sticking out of cellphone",
                                },
                              }),
                            ]),
                            _vm._v(" "),
                            _c("template", { slot: "title" }, [
                              _c("h3", { staticClass: "h5" }, [
                                _vm._v("Protect your online privacy"),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("template", { slot: "content" }, [
                              _c("p", [
                                _vm._v(
                                  "\n                    Most websites ignore your Human Right to privacy. Whether it's your internet service provider monitoring your connection\n                    or advertisers tracking you, exercising your right to privacy is becoming more difficult. With TunnelBear VPN, all of your\n                    browsing is encrypted so no one else can see what you're doing.\n                  "
                                ),
                              ]),
                            ]),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col col-12 col-md-4" },
                      [
                        _c(
                          "list-box",
                          [
                            _c("template", { slot: "img" }, [
                              _c("img", {
                                attrs: {
                                  src: require("./images/reasons/peer.svg"),
                                  alt: "Lightening fast bear",
                                },
                              }),
                            ]),
                            _vm._v(" "),
                            _c("template", { slot: "title" }, [
                              _c("h3", { staticClass: "h5" }, [
                                _vm._v("Peer-To-Peer"),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("template", { slot: "content" }, [
                              _c("p", [
                                _vm._v(
                                  "\n                    Internet service providers regularly slow, and even block, torrent connections on their networks. TunnelBear protects you\n                    from disconnects and blocks ISP from seeing your traffic, so they can't slow it down.\n                  "
                                ),
                              ]),
                            ]),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ]
          ),
          _vm._v(" "),
          _vm._m(3),
          _vm._v(" "),
          _vm._m(4),
          _vm._v(" "),
          _vm._m(5),
          _vm._v(" "),
          _c("section", { staticClass: "bg-light", attrs: { id: "install" } }, [
            _c("span", {
              staticClass: "anchor",
              attrs: { id: "how-to-install" },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "section-inner" }, [
              _c("div", { staticClass: "container" }, [
                _c("h2", { staticClass: "text-center" }, [
                  _vm._v("How do I install a VPN?"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row list" }, [
                  _c(
                    "div",
                    { staticClass: "col col-12 col-md-4" },
                    [
                      _c(
                        "img-item",
                        [
                          _c("template", { slot: "img" }, [
                            _c("img", {
                              attrs: {
                                src: require("./images/install/download-tunnelbear-apps.svg"),
                                alt: "TunnelBear App Icon",
                              },
                            }),
                          ]),
                          _vm._v(" "),
                          _c("template", { slot: "content" }, [
                            _c("h3", { staticClass: "h6" }, [
                              _vm._v("Download the app"),
                            ]),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(
                                "TunnelBear apps are available for PC, Mac, iPhone and Android."
                              ),
                            ]),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col col-12 col-md-4" },
                    [
                      _c(
                        "img-item",
                        [
                          _c("template", { slot: "img" }, [
                            _c("img", {
                              attrs: {
                                src: require("./images/install/create-your-tunnelbear-username-password.svg"),
                                alt: "Username and password form fields",
                              },
                            }),
                          ]),
                          _vm._v(" "),
                          _c("template", { slot: "content" }, [
                            _c("h3", { staticClass: "h6" }, [
                              _vm._v("Create an account"),
                            ]),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v("Use your email and create a password."),
                            ]),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col col-12 col-md-4" },
                    [
                      _c(
                        "img-item",
                        [
                          _c("template", { slot: "img" }, [
                            _c("img", {
                              attrs: {
                                src: require("./images/install/toggle-tunnelbear-on.svg"),
                                alt: "Toggle switch to turn TunnelBear on",
                              },
                            }),
                          ]),
                          _vm._v(" "),
                          _c("template", { slot: "content" }, [
                            _c("h3", { staticClass: "h6" }, [
                              _vm._v("Connect"),
                            ]),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v("Tap the button to connect to the VPN."),
                            ]),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "text-center btn-wrap" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "btn btn-lg",
                        attrs: { to: { name: "pricing" } },
                      },
                      [_vm._v("Get Started")]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("section", { staticClass: "bg-regular", attrs: { id: "use" } }, [
            _c("span", { staticClass: "anchor", attrs: { id: "how-to-use" } }),
            _vm._v(" "),
            _c("div", { staticClass: "section-inner" }, [
              _c("div", { staticClass: "container" }, [
                _c("div", { staticClass: "element medium centered" }, [
                  _c("h2", { staticClass: "text-center" }, [
                    _vm._v("How do I use my VPN once it has been set up?"),
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "\n              Once your VPN has been set up, just pick a country and turn it on. The VPN runs in the background as you browse. The most\n              noticeable difference is that you should have access to websites you didn't have access to before.\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "\n              Most VPN providers offer servers in different countries. This is how people regularly bypass censorship and unblock\n              websites. If you're trying to access something that's blocked in your country, connecting to a server in another country should give you access.\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "caption-img text-center" }, [
                    _vm.pageLoaded
                      ? _c("img", {
                          staticClass: "layout-img",
                          attrs: {
                            srcset:
                              require("./images/tunnelbear-dropdown-menu.png") +
                              " 1x, " +
                              require("./images/tunnelbear-dropdown-menu@2x.png") +
                              " 2x",
                            src: require("./images/tunnelbear-dropdown-menu@2x.png"),
                            alt: "TunnelBear VPN App dropdown menu showing some server locations: United States of America, United Kingdom, Canada, Germany, Japan, Australia, France, Italy, Netherlands, Sweden",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "Choose a country and switch TunnelBear on, that's it!"
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "section",
            { staticClass: "bg-regular smaller", attrs: { id: "faq" } },
            [
              _c("span", { staticClass: "anchor", attrs: { id: "vpn-faq" } }),
              _vm._v(" "),
              _c("div", { staticClass: "section-inner" }, [
                _c("div", { staticClass: "container" }, [
                  _c("div", { staticClass: "content" }, [
                    _c("h2", { staticClass: "text-center" }, [
                      _vm._v("VPN FAQ"),
                    ]),
                    _vm._v(" "),
                    _c("ul", { staticClass: "faq" }, [
                      _c(
                        "li",
                        [
                          _c(
                            "list-item",
                            [
                              _c("template", { slot: "title" }, [
                                _vm._v(
                                  "Do I just browse like I normally would?"
                                ),
                              ]),
                              _vm._v(" "),
                              _c("template", { slot: "content" }, [
                                _c("p", [
                                  _vm._v(
                                    "\n                      Yup. Just turn TunnelBear on and use the internet like you normally would. TunnelBear works in the background\n                      to protect your connection from eavesdroppers, so you shouldn't notice any difference in your browsing experience. Check out more information on how to use TunnelBear "
                                  ),
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: "https://help.tunnelbear.com/hc/en-us/articles/360030300111",
                                        target: "_blank",
                                      },
                                    },
                                    [_vm._v("here")]
                                  ),
                                  _vm._v(".\n                    "),
                                ]),
                              ]),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        [
                          _c(
                            "list-item",
                            [
                              _c("template", { slot: "title" }, [
                                _vm._v("Is my online privacy worth the cost?"),
                              ]),
                              _vm._v(" "),
                              _c("template", { slot: "content" }, [
                                _c("p", [
                                  _vm._v(
                                    "\n                      That entirely depends on how you feel about letting strangers at for-profit companies know the most intimate\n                      details of your life. In many countries, it's legal for internet service providers to record, package and sell\n                      your private information to any company interested in buying it.\n                    "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("p", [
                                  _vm._v(
                                    "\n                      Once your information is out there, it's sold multiple times until it eventually falls into the hands of ad-tech\n                      companies. With little to no oversight on how data is stored and protected, hackers can steal millions of users'\n                      information with little to no effort.\n                    "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("p", [
                                  _vm._v(
                                    '\n                      The cost of privacy is different for everyone. For some people, voluntarily giving away some personal information\n                      is a fair exchange for a "free" service. For others, not knowing who has their data or what they\'re doing with it\n                      is more than enough reason to use a VPN.\n                    '
                                  ),
                                ]),
                              ]),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        [
                          _c(
                            "list-item",
                            [
                              _c("template", { slot: "title" }, [
                                _vm._v(
                                  "How do I know that TunnelBear is working and keeping me secure?"
                                ),
                              ]),
                              _vm._v(" "),
                              _c("template", { slot: "content" }, [
                                _c("p", [
                                  _vm._v(
                                    "\n                      To check if TunnelBear is working, turn the app on, then visit "
                                  ),
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: "https://bearsmyip.com",
                                        target: "_blank",
                                      },
                                    },
                                    [_vm._v("Bearsmyip")]
                                  ),
                                  _vm._v(
                                    ". If the website shows that you're\n                      connected to a server in the country you've chosen, you're secure! You should also see a green banner that says,\n                      \"Your location is private and encrypted with a Bear.\"\n                    "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("p", [
                                  _vm._v(
                                    "If you are having issues connecting, please visit our connection help article."
                                  ),
                                ]),
                              ]),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        [
                          _c(
                            "list-item",
                            [
                              _c("template", { slot: "title" }, [
                                _vm._v(
                                  "Does TunnelBear store or log any information/data with my VPN?"
                                ),
                              ]),
                              _vm._v(" "),
                              _c("template", { slot: "content" }, [
                                _c(
                                  "p",
                                  [
                                    _vm._v(
                                      "\n                      TunnelBear will never log, or store, "
                                    ),
                                    _c(
                                      "router-link",
                                      {
                                        attrs: {
                                          to: {
                                            name: "privacy-policy",
                                            hash: "#1.3",
                                          },
                                          target: "_blank",
                                        },
                                      },
                                      [_vm._v("any of your usage data")]
                                    ),
                                    _vm._v(
                                      ". To run the service, we do need an email address we can\n                      reach you at – to confirm your account – and a payment method after you've upgraded.\n                    "
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        [
                          _c(
                            "list-item",
                            [
                              _c("template", { slot: "title" }, [
                                _vm._v(
                                  "Are there legal implications if I use VPN?"
                                ),
                              ]),
                              _vm._v(" "),
                              _c("template", { slot: "content" }, [
                                _c("p", [
                                  _vm._v(
                                    "\n                      In most countries, using a VPN is perfectly legal. However, in several places, like China and Russia, if you're caught\n                      using a VPN that isn't government-approved, you can be fined.\n                    "
                                  ),
                                ]),
                              ]),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        [
                          _c(
                            "list-item",
                            [
                              _c("template", { slot: "title" }, [
                                _vm._v(
                                  "Will using a VPN slow down my connection?"
                                ),
                              ]),
                              _vm._v(" "),
                              _c("template", { slot: "content" }, [
                                _c("p", [
                                  _vm._v(
                                    "\n                      With regular use, you shouldn't notice a difference in speed while using TunnelBear. Latency can be an issue if\n                      you're connected to a server on the other side of the world though. If you have speed issues, "
                                  ),
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: "https://help.tunnelbear.com/hc/en-us/articles/360006839792",
                                        target: "_blank",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " please see our\n                      troubleshooting article"
                                      ),
                                    ]
                                  ),
                                  _vm._v(
                                    " to help find the cause.\n                    "
                                  ),
                                ]),
                              ]),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        [
                          _c(
                            "list-item",
                            [
                              _c("template", { slot: "title" }, [
                                _vm._v(
                                  "I have nothing to hide, so why should I use a VPN?"
                                ),
                              ]),
                              _vm._v(" "),
                              _c("template", { slot: "content" }, [
                                _c("p", [
                                  _vm._v(
                                    "\n                      Having nothing to hide and giving up your privacy are two very different things. Just because you're a law-abiding\n                      citizen, doesn't mean you'd keep your curtains open while you're changing. VPN protect your right to privacy from\n                      people that want to know your most intimate thoughts and personal moments.\n                    "
                                  ),
                                ]),
                              ]),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        [
                          _c(
                            "list-item",
                            [
                              _c("template", { slot: "title" }, [
                                _vm._v("Am I 100% secure with a VPN?"),
                              ]),
                              _vm._v(" "),
                              _c("template", { slot: "content" }, [
                                _c("p", [
                                  _vm._v(
                                    "\n                      VPN make it harder for attackers to find you and threaten your online security. They won't make you anonymous or protect\n                      you from every online threat, but no single product can do that. There are a lot of online threats the require specific\n                      tools for each one. VPN are one of those tools, like an adblocker or antivirus.\n                    "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("p", [
                                  _vm._v(
                                    'Basically, if any VPN provider claims to give you "complete anonymity and security," they\'re lying.'
                                  ),
                                ]),
                              ]),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        [
                          _c(
                            "list-item",
                            [
                              _c("template", { slot: "title" }, [
                                _vm._v(
                                  "How do I know if my VPN connection is working?"
                                ),
                              ]),
                              _vm._v(" "),
                              _c("template", { slot: "content" }, [
                                _c("p", [
                                  _vm._v("\n                      Visiting "),
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: "https://bearsmyip.com",
                                        target: "_blank",
                                      },
                                    },
                                    [_vm._v("Bearsmyip")]
                                  ),
                                  _vm._v(
                                    " will quickly show you if you're securely connected to the TunnelBear network. If you're having issues connecting,\n                      please visit our connection help article.\n                    "
                                  ),
                                ]),
                              ]),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        [
                          _c(
                            "list-item",
                            [
                              _c("template", { slot: "title" }, [
                                _vm._v(
                                  "What is a DNS leak and will I ever experience one with TunnelBear VPN?"
                                ),
                              ]),
                              _vm._v(" "),
                              _c("template", { slot: "content" }, [
                                _c("p", [
                                  _vm._v(
                                    "\n                      A DNS leak happens when your VPN is on, but you connect to a website through your internet service provider's DNS servers outside\n                      your tunnel.\n                    "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("p", [
                                  _vm._v(
                                    "\n                      You will never experience a DNS leak with TunnelBear because we have our own secure DNS servers. When you connect to TunnelBear,\n                      your DNS requests happen inside your tunnel, like all your other data, keeping your searches safe from prying eyes.\n                    "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("p", [
                                  _vm._v(
                                    "If you have any questions about DNS leaks, please see our "
                                  ),
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: "https://help.tunnelbear.com/hc/en-us/articles/360007120871-Does-TunnelBear-protect-against-DNS-leaks-",
                                        target: "_blank",
                                      },
                                    },
                                    [_vm._v("help article")]
                                  ),
                                  _vm._v(
                                    ", or contact our friendly Support Bears."
                                  ),
                                ]),
                              ]),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        [
                          _c(
                            "list-item",
                            [
                              _c("template", { slot: "title" }, [
                                _vm._v(
                                  "Where are the TunnelBear servers located?"
                                ),
                              ]),
                              _vm._v(" "),
                              _c("template", { slot: "content" }, [
                                _c("p", [
                                  _vm._v("TunnelBear has servers in "),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn plain link",
                                      on: {
                                        click: function ($event) {
                                          return _vm.openModal()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.tunnels.length) +
                                          " countries"
                                      ),
                                    ]
                                  ),
                                  _vm._v("."),
                                ]),
                              ]),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "section",
            { staticClass: "bg-regular", attrs: { id: "tunnelbear" } },
            [
              _c("span", {
                staticClass: "anchor",
                attrs: { id: "why-tunnelbear" },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "section-inner" }, [
                _c("div", { staticClass: "container" }, [
                  _vm._m(6),
                  _vm._v(" "),
                  _c("div", { staticClass: "caption-img text-center" }, [
                    _vm.pageLoaded
                      ? _c("img", {
                          staticClass: "layout-img",
                          attrs: {
                            srcset:
                              require("./images/tunnelbear-apps-devices.png") +
                              " 1x, " +
                              require("./images/tunnelbear-apps-devices@2x.png") +
                              " 2x",
                            src: require("./images/tunnelbear-apps-devices@2x.png"),
                            alt: "Collection of devices TunnelBear VPN is compatible with: Windows, macOS, Android and iOS",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "Get TunnelBear on your iPhone, iPad, Android Phone or Tablet, Mac or PC."
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass: "pull-quote text-center",
                      attrs: { "aria-hidden": "true" },
                    },
                    [
                      _vm._v(
                        "\n            Browse from anywhere with peace of mind, knowing that your online activity is more private from\n            hackers, ISPs, and advertisers.\n          "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "element medium centered" }, [
                    _c("p", [
                      _vm._v(
                        "\n              Flexible pricing lets you decide whether you would like to be billed monthly or annually (there's a discount for\n              annual memberships).\n            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "p",
                      [
                        _vm._v(
                          "\n              TunnelBear does not, and will never, log your usage or connection information. Privacy is a Universal Human\n              Right that we respect and fight for. Our "
                        ),
                        _c(
                          "router-link",
                          { attrs: { to: { name: "privacy-policy" } } },
                          [_vm._v("Privacy Policy")]
                        ),
                        _vm._v(
                          " outlines our stance on privacy and customer data.\n            "
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("section", { staticClass: "bg-alt", attrs: { id: "download" } }, [
            _c("div", { staticClass: "section-inner" }, [
              _c("div", { staticClass: "container text-center" }, [
                _c(
                  "div",
                  { staticClass: "element medium copy centered" },
                  [
                    _c("img", {
                      attrs: {
                        src: require("@/images/tb-icon.svg"),
                        alt: "TunnelBear App Icon",
                      },
                    }),
                    _vm._v(" "),
                    _c("h2", [
                      _vm._v(
                        "Download TunnelBear to start browsing privately today!"
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "router-link",
                      {
                        staticClass: "btn btn-lg",
                        attrs: { to: { name: "pricing" } },
                      },
                      [_vm._v("Get TunnelBear")]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          !_vm.isAiBlockedCountry ? _c("ai-chat-bot") : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("section", { staticClass: "bg-regular smaller" }, [
      _c("div", { staticClass: "section-inner" }, [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "element medium centered" }, [
            _c("p", [
              _vm._v(
                "\n              If your device is connected to the internet, your internet service provider can see everything you do.\n              Every search. Every file you download. Everything. To protect their privacy, more people are using virtual\n              private networks, or VPN for short, to stop companies from tracking what they do online.\n            "
              ),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n              To help put an end to the misinformation surrounding VPN, we'll explain what a VPN is, how it works,\n              why you need one, and what a VPN can do for you.\n            "
              ),
            ]),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "element medium centered" }, [
      _c("h2", { staticClass: "text-center" }, [_vm._v("What is a VPN?")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n              A VPN is an app that keeps your internet connection private, whether you're connecting to unsafe public\n              Wi-Fi or your network at home. Having a layer of security that blocks people from watching you browse helps\n              keep you safe online, no matter where you connect from.\n            "
        ),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n              Virtual private networks protect you by creating an encrypted \"tunnel\" that all of your device's data\n              travels through on its way to the internet. Encryption turns words and data, like text files, into a\n              secret code. If someone tries to read encrypted data without the password, they'll see random gibberish.\n            "
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "caption-img text-center" }, [
        _c("img", {
          staticClass: "layout-img",
          attrs: {
            srcset:
              require("./images/encryption.png") +
              " 1x, " +
              require("./images/encryption@2x.png") +
              " 2x",
            src: require("./images/encryption@2x.png"),
            alt: "VPN encryption turning contracts, credit cards, banking information and personal data into secret code",
          },
        }),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "TunnelBear makes your data unreadable between you and its destination"
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n              A VPN acts as your gateway for accessing the internet privately. VPN software can be installed on\n              most popular devices, like your phone, laptop and desktop. One important thing to remember is a VPN\n              provider isn't the same as an internet service provider. You'll still need an ISP to connect to the\n              internet, your VPN runs on top of that connection.\n            "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "element medium centered" }, [
      _c("h2", { staticClass: "text-center" }, [
        _vm._v("Why do I need a VPN?"),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n              The reasons why you need a VPN are personal, and a little different for everyone. Some people use VPN to bypass app restrictions\n              and stay in touch with loved ones while traveling in heavily censored countries. Other people use VPN to stay secure on public Wi-Fi.\n              Some people, just like knowing their internet service provider can't see what they're browsing every time they go online.\n            "
        ),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n              While the benefits of using VPN are just starting to go mainstream, it wasn't long ago that people had trouble seeing the\n              importance of antivirus. As hacking and viruses became regular news stories, people realized how dangerous just browsing a website could\n              be. "
        ),
        _c(
          "a",
          {
            attrs: {
              href: "https://www.tunnelbear.com/blog/always-use-a-vpn/",
              target: "_blank",
            },
          },
          [_vm._v("Loss of privacy")]
        ),
        _vm._v(
          ' has become the new "virus,"\n              and while most devices have some type of virus protection, very few have any kind of privacy protection.\n            '
        ),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n              Just like you need protection from viruses, you need to protect yourself from privacy threats. Using a VPN wraps your\n              "
        ),
        _c(
          "a",
          {
            attrs: {
              href: "https://www.tunnelbear.com/blog/stronger-encryption/",
              target: "_blank",
            },
          },
          [_vm._v("browsing in a layer of encryption")]
        ),
        _vm._v(
          ", making it harder\n              for people to spy on you online.\n            "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("section", { staticClass: "bg-regular", attrs: { id: "how" } }, [
      _c("span", { staticClass: "anchor", attrs: { id: "how-it-works" } }),
      _vm._v(" "),
      _c("div", { staticClass: "section-inner" }, [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "element medium centered" }, [
            _c("h2", { staticClass: "text-center" }, [
              _vm._v("What does a VPN do and how does it work?"),
            ]),
            _vm._v(" "),
            _c("h3", { staticClass: "h5" }, [_vm._v("Security")]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n              A VPN encrypts your connection and protects your personal data from being intercepted and read by attackers. Typical things attackers\n              try to steal are passwords, credit card information, and private messages.\n            "
              ),
            ]),
            _vm._v(" "),
            _c("h3", { staticClass: "h5" }, [_vm._v("Privacy")]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n              Using a VPN adds a layer of privacy to your online life. A VPN prevents your ISP, employer, or network owner from logging all the\n              sites you visit, and stops websites from following your IP address around the internet.\n            "
              ),
            ]),
            _vm._v(" "),
            _c("h3", { staticClass: "h5" }, [_vm._v("Access")]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n              VPN provide access to an open and unrestricted internet. By connecting to servers in other countries, VPN help you bypass government\n              censorship bans on news sites, social networks, games, or other websites you love.\n            "
              ),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n              VPN work by creating a secure tunnel that protects your device's connection to the internet. That protection is a layer of encryption,\n              or coded language, only your VPN app and the server understand. With an encrypted connection, no one can monitor your online activity.\n              If someone tried to track what you were doing, they would only see a random series of numbers and letters.\n            "
              ),
            ]),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "section",
      { staticClass: "bg-light", attrs: { id: "with-tunnelbear" } },
      [
        _c("div", { staticClass: "section-inner" }, [
          _c("div", { staticClass: "container" }, [
            _c("div", { staticClass: "element xs centered text-center" }, [
              _c("h2", [_vm._v("With TunnelBear")]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "Your data is protected inside an encrypted tunnel from ISPs, network owners, and hackers."
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("img", {
              staticClass: "layout-img desktop",
              attrs: {
                srcset:
                  require("./images/encrypted-tunnel.png") +
                  " 1x, " +
                  require("./images/encrypted-tunnel@2x.png") +
                  " 2x",
                src: require("./images/encrypted-tunnel@2x.png"),
                alt: "TunnelBear sends your browsing through an encrypted tunnel, keeping you safe from ISP and hackers",
              },
            }),
            _vm._v(" "),
            _c("img", {
              staticClass: "layout-img mobile",
              attrs: {
                srcset:
                  require("./images/encrypted-tunnel-mobile.png") +
                  " 1x, " +
                  require("./images/encrypted-tunnel-mobile@2x.png") +
                  " 2x",
                src: require("./images/encrypted-tunnel-mobile@2x.png"),
                alt: "TunnelBear sends your browsing through an encrypted tunnel, keeping you safe from ISP and hackers",
              },
            }),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "section",
      { staticClass: "bg-regular", attrs: { id: "client" } },
      [
        _c("span", { staticClass: "anchor", attrs: { id: "vpn-client" } }),
        _vm._v(" "),
        _c("div", { staticClass: "section-inner" }, [
          _c("div", { staticClass: "container" }, [
            _c("div", { staticClass: "element medium centered" }, [
              _c("h2", { staticClass: "text-center" }, [
                _vm._v("What does a VPN client do exactly?"),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n              To use VPN software, you need something called a VPN client. \"Client\" is really just another term for app, but the difference is that\n              clients act as a gateway between your device and the service you're trying to connect to. Clients aren't a standalone app.\n            "
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n              Your device connects to the VPN server through the VPN client. Once you're connected, the client gives you access to the VPN service.\n            "
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n              VPN aren't the only software that use clients. Some well-known examples of clients are online games like Fortnite or the Adobe Creative Suite.\n            "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "pull-quote text-center",
                attrs: { "aria-hidden": "true" },
              },
              [
                _vm._v(
                  "\n            ...clients act as a gateway between your device and the service...\n          "
                ),
              ]
            ),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "element medium centered" }, [
      _c("h2", { staticClass: "text-center" }, [
        _vm._v("Why TunnelBear is the right VPN solution for you"),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n              TunnelBear works quietly in the background to protect all of your devices. With apps for phones, laptops, desktops and browser\n              extensions, you can secure all of your data, no matter what device you're on, or where you are.\n            "
        ),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n              Using TunnelBear is easy. Once you've installed TunnelBear, you just pick a country and turn it on. All of\n              the complicated work is done for you.\n            "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }