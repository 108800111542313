var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("nav", { attrs: { id: "main-header" } }, [
    _c("div", { staticClass: "nav-inner complex" }, [
      _c(
        "div",
        { staticClass: "top-bar" },
        [
          _c(
            "router-link",
            { staticClass: "logo teams", attrs: { to: { name: "teams" } } },
            [
              _c("img", {
                attrs: {
                  src: require("../../images/tb-teams.svg"),
                  alt: "TunnelBear for Teams",
                },
              }),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "drawer" }, [
        _c("div", { attrs: { id: "nav-items" } }, [
          _c("div", { staticClass: "nav-menu" }, [
            _c(
              "div",
              { staticClass: "nav-items-wrap" },
              [
                _c("div", { staticClass: "nav-item" }, [
                  _c(
                    "button",
                    {
                      staticClass: "plain",
                      on: {
                        click: function ($event) {
                          return _vm.scrollPricingIntoView()
                        },
                      },
                    },
                    [_vm._v("Plans")]
                  ),
                ]),
                _vm._v(" "),
                _c("nav-link-apps", {
                  attrs: { current: _vm.current },
                  on: {
                    dropdownClicked: function ($event) {
                      return _vm.toggleMobileMenu()
                    },
                  },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "nav-item" },
                  [
                    _vm.isTeamUser
                      ? _c("a", { attrs: { href: "/team/account/overview" } }, [
                          _vm._v("My Account"),
                        ])
                      : _c(
                          "router-link",
                          { attrs: { to: { name: "overview" } } },
                          [_vm._v("My Account")]
                        ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "nav-item" },
                  [
                    _c("router-link", { attrs: { to: { name: "download" } } }, [
                      _vm._v("Download"),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _vm._m(0),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "nav-item bottom" }, [
      _c(
        "a",
        { staticClass: "btn hidden-xs", attrs: { href: "/team/create" } },
        [
          _vm._v("Try it free"),
          _c("span", { staticClass: "hidden-xs" }, [_vm._v(" for 7 days")]),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }