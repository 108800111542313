var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "view-wrap" }, [
    _c("div", { staticClass: "view-content" }, [
      _c("section", { attrs: { id: "platforms" } }, [
        _c(
          "div",
          { staticClass: "platforms bg-alt" },
          _vm._l(_vm.apps, function (app) {
            return _c(
              "router-link",
              {
                key: app.id,
                staticClass: "platform-wrap",
                attrs: { to: { path: "/apps/" + app.toLowerCase() + "/" } },
              },
              [
                _c("div", { class: "platform " + app.toLowerCase() }),
                _vm._v(" "),
                _c("p", { staticClass: "hidden-xs" }, [_vm._v(_vm._s(app))]),
              ]
            )
          }),
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "bg-regular", attrs: { id: "apps" } },
        [_c("router-view")],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }