var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "form",
        {
          staticClass: "form-area",
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.submit.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "form-section" },
            [
              _c("announce", { attrs: { addClass: "full-width" } }, [
                _c(
                  "p",
                  {
                    staticClass: "field-error",
                    class: { "show-error": _vm.showError },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.otherError ||
                            "Grrr... Is that email address valid?"
                        ) +
                        "\n        "
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c(
                "dynamic-input",
                {
                  attrs: { name: "email", type: "text", required: "" },
                  on: {
                    model: function ($event) {
                      _vm.email = $event
                    },
                  },
                },
                [_vm._v("Email")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "submit-button",
        {
          attrs: { finished: _vm.finished, submitting: _vm.submitting },
          on: { submit: _vm.submit },
        },
        [_vm._v("Submit")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }