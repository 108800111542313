var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("div", { staticClass: "ai-chat-bot-container" }, [
        _c("div", { staticClass: "message-container" }, [
          _c("div", { staticClass: "message-header" }, [
            _c("div", { staticClass: "logo-container" }, [
              _c("img", {
                staticClass: "logo",
                attrs: { src: require("./images/title-logo.svg"), alt: "logo" },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "minimize-container" }, [
              _c("button", { staticClass: "minimize-btn" }, [
                _c("img", {
                  staticClass: "minimize-img",
                  attrs: {
                    src: require("@/../static/images/minimize.svg"),
                    alt: "collapse",
                  },
                }),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "message-content" }, [
            _c("div", { staticClass: "messages m-bottom-10" }, [
              _c("div", { staticClass: "messages-list" }, [
                _c("div", { staticClass: "msg-ai-container" }, [
                  _c("div", { staticClass: "welcome-msg-container mtop-50" }, [
                    _c("span", { staticClass: "avtr" }, [
                      _c("img", {
                        staticClass: "welcome-msg-avtr",
                        attrs: {
                          src: require("./images/chat-icon.svg"),
                          alt: "",
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "msg-ai welcome-msg" }, [
                      _c("p", { staticClass: "hello-txt" }, [
                        _c("b", [_vm._v("HELLO!")]),
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "info-txt" }, [
                        _c("b", [
                          _vm._v(
                            "I’m RoboCub, your very own personal AI assistant. I’m not as smart as a real bear, but will do my best to help."
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "msg-ai welcome-sub-info" }, [
                    _c("div", { staticClass: "chat-bubble mtop-5" }, [
                      _c("p", [
                        _vm._v("Do you have any questions about TunnelBear?"),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "res-txt-bot mtop-5 pbottom-10" },
                      [
                        _c("p", [
                          _vm._v(
                            "If you aren’t sure, here are some common topics I typically see:\n                    "
                          ),
                          _c("ul", { staticClass: "mleft-15" }, [
                            _c("li", [_vm._v("Account issues")]),
                            _vm._v(" "),
                            _c("li", [_vm._v("Technical questions")]),
                            _vm._v(" "),
                            _c("li", [_vm._v("General questions")]),
                            _vm._v(" "),
                            _c("li", [_vm._v("Feedback")]),
                          ]),
                        ]),
                      ]
                    ),
                  ]),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("form", { staticClass: "mtop-10", attrs: { id: "msg_form" } }, [
              _c("div", { staticClass: "input-container input-blank" }, [
                _c("input", {
                  attrs: {
                    id: "msg_input",
                    type: "text",
                    placeholder: "Send a message...",
                  },
                }),
                _vm._v(" "),
                _c(
                  "button",
                  { staticClass: "send-msg", attrs: { type: "submit" } },
                  [
                    _c("img", {
                      attrs: {
                        src: require("./images/submit-chat-btn.svg"),
                        alt: "submit",
                      },
                    }),
                  ]
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("button", { staticClass: "chat-icon-btn" }, [
        _c("img", {
          attrs: {
            src: require("./images/ai-agent-bear.svg"),
            alt: "chat-icon",
          },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }