var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { attrs: { id: "audit" } }, [
    _c("div", { staticClass: "section-inner" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "content element large centered" }, [
          _c(
            "div",
            { staticClass: "copy text-center" },
            [
              _c("img-set", {
                attrs: {
                  lazyload: "",
                  classes: "lg-screen",
                  imgSrc: require("@/components/audit/images/audit_badge.svg"),
                  alt: "Independently Audited Code",
                  width: 115,
                  height: 53,
                },
              }),
              _vm._v(" "),
              _c("h2", [_vm._v("Independently audited")]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "We take security seriously. TunnelBear is the only VPN in the world to publish regular, independent "
                ),
                _c("a", { attrs: { href: this.external.audit } }, [
                  _vm._v("security audits"),
                ]),
                _vm._v("."),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "img-holder" },
            [
              _c("img-set", {
                attrs: {
                  lazyload: "",
                  classes: "lg-screen",
                  imgSrc: require("@/components/audit/images/audit.svg"),
                  alt: "Independently Audited Code",
                  width: 438,
                  height: 473,
                },
              }),
              _vm._v(" "),
              _c("img-set", {
                attrs: {
                  lazyload: "",
                  classes: "sm-screen",
                  imgSrc: require("@/components/audit/images/audit_bear_badge.svg"),
                  alt: "Independently Audited Code",
                  width: 279,
                  height: 301,
                },
              }),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }