var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { class: _vm.bg, attrs: { id: "network" } }, [
    _c("div", { staticClass: "section-inner" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row vertical-center" }, [
          _c(
            "div",
            { staticClass: "world col-lg-5 hidden-xs" },
            [
              _c("img-set", {
                attrs: {
                  lazyload: "",
                  imgSrc: require("@/components/country-access/images/earth-cutaway.svg"),
                  width: 51,
                  height: 80,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "text col-xs-12 col-md-7" },
            [
              _vm._t("copy", function () {
                return [
                  _c("h1", { staticClass: "h3" }, [
                    _vm._v(
                      "Browse the internet from " +
                        _vm._s(_vm.tunnels.length) +
                        " countries"
                    ),
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "Discover something new, or tunnel back home to enjoy your favourite websites and apps while travelling or living abroad."
                    ),
                  ]),
                ]
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "countries-container" },
                [_c("country-flags", { attrs: { rows: "rows" } })],
                1
              ),
            ],
            2
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }