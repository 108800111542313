var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "box-wrap" }, [
    _c(
      "div",
      { staticClass: "box", class: { expanded: _vm.expanded } },
      [
        _vm._t("img"),
        _vm._v(" "),
        _vm._t("title"),
        _vm._v(" "),
        _vm._t("content"),
      ],
      2
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "box mobile", class: { expanded: _vm.expanded } },
      [
        _c(
          "button",
          {
            staticClass: "btn faq-title plain dark",
            attrs: { "aria-label": "learn more" },
            on: {
              click: function ($event) {
                _vm.expanded = !_vm.expanded
              },
            },
          },
          [
            _vm._t("title"),
            _vm._v(" "),
            _c("img", {
              class: { expanded: _vm.expanded },
              attrs: {
                src: require("./images/plus.png"),
                alt: _vm.expanded ? "minimize list item" : "expand list item",
              },
            }),
          ],
          2
        ),
        _vm._v(" "),
        _c("transition", { attrs: { name: "slide-fade" } }, [
          _vm.expanded
            ? _c(
                "div",
                { staticClass: "expand-content" },
                [_vm._t("content"), _vm._v(" "), _vm._t("img")],
                2
              )
            : _vm._e(),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }