var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("iframe", {
    staticStyle: { display: "none" },
    attrs: {
      id: "download-frame",
      "data-cy": "link--iframe",
      src: _vm.frameSrc,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }