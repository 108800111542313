var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "bg-light", attrs: { id: "hello-bar" } }, [
    _c("div", { staticClass: "hello-bar-inner" }, [
      _c("div", { staticClass: "content content-left" }, [
        _c("p", { staticClass: "hidden-xs" }, [
          _c("strong", [_vm._v(_vm._s(_vm.sale.tagline))]),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "visible-xs" }, [
          _c("strong", [_vm._v(_vm._s(_vm.sale.tagline))]),
        ]),
        _vm._v(" "),
        !_vm.hideCtaButton
          ? _c(
              "div",
              { staticClass: "btn-wrap" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "btn",
                    attrs: {
                      to: _vm.sale.couponSale
                        ? _vm.sale.bannerRoute
                        : { name: "checkout" },
                      "data-cy": "btn--hello-cta",
                    },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.sale.titleButton) +
                        "\n        "
                    ),
                  ]
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _vm.sale.countdown.show
        ? _c(
            "div",
            { staticClass: "content content-right" },
            [
              _vm.sale.name === "holiday"
                ? _c("div", {
                    staticClass: "lights",
                    class: { off: !_vm.lightsOn },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("p", { staticClass: "bold hidden-xs" }, [
                _vm._v("Offer ends in"),
              ]),
              _vm._v(" "),
              _c("countdown-timer", {
                attrs: { targetDate: _vm.sale.countdown.endDate },
              }),
            ],
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }