var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "nav-dropdown" }, [
    _c("div", { staticClass: "dropdown-desktop" }, [
      _c("div", { staticClass: "dropdown-wrap" }, [
        _c(
          "div",
          {
            staticClass: "nav-item chevron-icon chevron-focus-rotate",
            on: { click: _vm.handleLinkClick },
          },
          [
            _vm._t("link"),
            _vm._v(" "),
            _c("div", { staticClass: "dropdown-content-wrap" }, [
              _c("div", { staticClass: "dropdown-container" }, [
                _c(
                  "div",
                  {
                    staticClass: "dropdown-list",
                    on: { click: _vm.handleContentClick },
                  },
                  [_vm._t("content")],
                  2
                ),
                _vm._v(" "),
                _c("div", { staticClass: "caret" }),
              ]),
            ]),
          ],
          2
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "dropdown-mobile" }, [
      _c("div", { staticClass: "dropdown-wrap" }, [
        _c("div", { staticClass: "link-wrap" }, [
          _c(
            "div",
            { staticClass: "nav-item", on: { click: _vm.handleLinkClick } },
            [_vm._t("link")],
            2
          ),
          _vm._v(" "),
          _c("button", {
            staticClass: "btn-expand chevron-icon",
            class: { rotate: _vm.showDropdown },
            attrs: { "aria-label": "expand navigation" },
            on: {
              click: function ($event) {
                _vm.isDropdown = !_vm.isDropdown
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "dropdown-content-wrap",
            class: { expand: _vm.showDropdown },
          },
          [
            _c("div", { staticClass: "dropdown-container" }, [
              _c(
                "div",
                {
                  staticClass: "dropdown-list",
                  attrs: { inert: !_vm.showDropdown },
                  on: { click: _vm.handleContentClick },
                },
                [_vm._t("content")],
                2
              ),
            ]),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }