import { render, staticRenderFns } from "./nav-teams.vue?vue&type=template&id=db64c668"
import script from "./nav-teams.vue?vue&type=script&lang=js"
export * from "./nav-teams.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/build/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('db64c668')) {
      api.createRecord('db64c668', component.options)
    } else {
      api.reload('db64c668', component.options)
    }
    module.hot.accept("./nav-teams.vue?vue&type=template&id=db64c668", function () {
      api.rerender('db64c668', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/nav/components/nav-teams/nav-teams.vue"
export default component.exports