var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "transition",
    { attrs: { name: _vm.isOverlay ? "minimize" : "fade" } },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showModal,
              expression: "showModal",
            },
          ],
          ref: "modal",
          staticClass: "view-wrap",
          attrs: {
            role: "dialog",
            "aria-labelledby": _vm.labelID,
            "data-cy": "group--modal",
          },
        },
        [
          _c("div", {
            staticClass: "grey-bg",
            on: {
              click: function ($event) {
                _vm.closeable && _vm.closeModal()
              },
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "modal-wrap",
              class: [_vm.size, { overlay: _vm.isOverlay }],
            },
            [
              _c("div", { staticClass: "form-wrap" }, [
                _vm.closeable
                  ? _c("button", {
                      staticClass: "plain close",
                      attrs: { "aria-label": "close" },
                      on: {
                        click: function ($event) {
                          return _vm.closeModal()
                        },
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "form" }, [
                  _c(
                    "div",
                    { staticClass: "form-content-holder" },
                    [_vm._t("default")],
                    2
                  ),
                ]),
              ]),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }