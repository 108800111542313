var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      staticClass: "btn btn-lg full-width submit-btn",
      attrs: {
        type: "submit",
        disabled:
          _vm.paySubmitting || _vm.submitting || _vm.finished || _vm.disabled,
      },
      on: {
        click: function ($event) {
          $event.preventDefault()
          return _vm.submit.apply(null, arguments)
        },
      },
    },
    [
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.finished
          ? _c("div", {
              key: "finished",
              staticClass: "button-state checkmark",
            })
          : _vm.paySubmitting || _vm.submitting
          ? _c("div", {
              key: "submitting",
              staticClass: "button-state spinner",
            })
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "text",
          class: {
            hidden: _vm.paySubmitting || _vm.submitting || _vm.finished,
          },
        },
        [_vm._t("default")],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }