var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("nav-links-dropdown", {
    on: {
      dropdownClicked: function ($event) {
        return _vm.$emit("dropdownClicked")
      },
    },
    scopedSlots: _vm._u([
      {
        key: "link",
        fn: function () {
          return [
            _c(
              "router-link",
              {
                class: { current: _vm.current },
                attrs: { to: { name: "what-is-vpn" } },
              },
              [_vm._v("What is a VPN?")]
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "content",
        fn: function () {
          return [
            _c("ul", [
              _c(
                "li",
                [
                  _c(
                    "router-link",
                    { attrs: { to: { name: "what-is-vpn" } } },
                    [_vm._v("Why VPN? ")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                [
                  _c("router-link", { attrs: { to: { name: "features" } } }, [
                    _vm._v("Features"),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c("li", [
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://www.tunnelbear.com/blog/always-use-a-vpn/",
                    },
                  },
                  [_vm._v("\n          Online Privacy\n        ")]
                ),
              ]),
              _vm._v(" "),
              _c("li", [
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://www.tunnelbear.com/blog/staying-safe-in-your-cave-how-to-work-from-home-with-tunnelbear/",
                    },
                  },
                  [_vm._v("\n          VPN for WFH\n        ")]
                ),
              ]),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }