var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "nav-menu" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "nav-item visible-xs" }, [
      _c(
        "button",
        {
          staticClass: "plain",
          on: {
            click: function ($event) {
              return _vm.$emit("logout")
            },
          },
        },
        [_vm._v("Logout")]
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "nav-item bottom",
        on: {
          click: function ($event) {
            return _vm.$emit("closeNav")
          },
        },
      },
      [
        _c(
          "router-link",
          {
            staticClass: "btn",
            class: { hollow: _vm.current === "download" },
            attrs: { to: { name: "download" } },
          },
          [_vm._v("Download")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "nav-item" }, [
      _c("a", { attrs: { href: "/team/account/overview/member" } }, [
        _vm._v("My Account"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }