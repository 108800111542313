<template>
  <div class="view-wrap">
    <div class="view-content">
      <section v-if="!isSanctioned && !isCountryIndia" class="top-section smaller">
        <div class="section-inner">
          <h1 class="text-center">Pick the plan that's right for&nbsp;you</h1>
          <div class="mobile-menu">
            <button @click="mobileSelect = 'free'" :class="{'selected': mobileSelect === 'free'}">Free</button>
            <button @click="mobileSelect = 'unlimited'" :class="{'selected': mobileSelect === 'unlimited'}">Unlimited</button>
            <button @click="mobileSelect = 'teams'" :class="{'selected': mobileSelect === 'teams'}">Teams</button>
          </div>
        </div>
      </section>
      <section id="bear-plans" :class="{'free-instead': isSanctioned || isCountryIndia}">
        <div class="section-inner">
          <div v-if="!isSanctioned && !isCountryIndia" class="plans" :class="'select-' + mobileSelect">
            <div class="item-free item-column">
              <div class="bear-pic">
                <div class="bear-img-wrap free">
                  <img class="bear large" src="./images/bear-free.svg" alt="">
                  <img class="bear small" src="./images/mobile-free.svg" alt="">
                </div>
                <h2 class="h2">Free</h2>
              </div>
              <div class="inner-wrap">
                <div class="inner">
                  <div class="content">
                    <p>Testing and limited usage</p>
                    <ul class="check-list yellow copy">
                      <li>2GB of secure browsing</li>
                    </ul>
                    <router-link aria-label="Download TunnelBear for Free with a data limit of 2GB per month" class="btn btn-lg" :to="{name: 'download'}">Try for free</router-link>
                  </div>
                </div>
              </div>
            </div>
            <div class="item-unlimited item-column">
              <div class="bear-pic">
                <!-- sale-only -->
                <div class="bear-img-wrap unlimited">
                  <img v-if="sale.isLive && sale.name === 'winter-sale'" src="./images/bear-yearly-winter.svg" alt="">
                  <img v-else-if="sale.isLive && sale.name === 'spring-sale'" src="./images/bear-yearly-spring.svg" alt="spring-sale-bear">
                  <img v-else-if="sale.isLive && sale.name === 'summer-sale'" class="summer-sale" src="./images/bear-yearly-summer.svg" alt="summer-sale-bear">
                  <img v-else srcset="./images/bear-yearly.png 1x, ./images/bear-yearly@2x.png 2x" src="./images/bear-yearly@2x.png" alt="">
                </div>
                <h2 :class="{'h2': true, 'spring-sale': sale.isLive && sale.name === 'spring-sale'}">Unlimited</h2>
              </div>
              <div class="inner-wrap">
                <div class="inner">
                  <div class="content">
                    <p>All-day security and peace of mind</p>
                    <ul class="check-list copy">
                      <li>Unlimited secure browsing</li>
                      <li>Unlimited devices</li>
                      <li>Premium VPN servers</li>
                      <li>City-level server selection</li>
                      <li>Priority customer support</li>
                    </ul>
                    <div :class="{'pricing': true, 'seasonal': sale.isLive && sale.name === 'seasonal'}">
                      <p>from <span class="amount" :class="{'green-text': sale.isLive && sale.name === 'spring-sale' }">${{ pricingValues[currentProduct].saleMonthlyPrice || pricingValues[currentProduct].monthlyPrice }}</span>/mo</p>
                    </div>
                    <router-link :aria-label="'Get Unlimited TunnelBear for $' + pricingValues[currentProduct].billingPrice + 'USD per year'" class="btn btn-lg" :class="{'btn-decor': sale.isLive && sale.name === 'holiday' }" :to="unlimitedUrl">Get started</router-link>
                  </div>
                </div>
              </div>
            </div>
            <div class="item-teams item-column">
              <div class="bear-pic">
                <div class="bear-img-wrap teams">
                  <img src="./images/bear-teams.svg" alt="">
                </div>
                <h2 class="h2">Teams</h2>
              </div>
              <div class="inner-wrap">
                <div class="inner">
                  <div class="content">
                    <p>For businesses securing 2+ users</p>
                    <ul class="check-list copy">
                      <li>Unlimited secure browsing</li>
                      <li>Unlimited devices</li>
                      <li>Premium VPN servers</li>
                      <li>City-level server selection</li>
                      <li>Priority customer support</li>
                      <li>Centralized team billing</li>
                      <li>Admin &amp; account manager tools</li>
                    </ul>
                    <div class="pricing">
                      <p><span class="amount">${{ pricingValues.TB4T_YEARLY_PAYMENT.monthlyPrice }}</span>/user per month</p>
                    </div>
                    <router-link :aria-label="'Get TunnelBear for Teams for $' + pricingValues.TB4T_YEARLY_PAYMENT.monthlyPrice + 'USD per month'" class="btn btn-lg" :to="{name: 'teams'}">Get started</router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="!isSanctioned && !isCountryIndia" class="payment-info">
            <img class="payment-img" src="./images/payment-options.svg" alt="Acceptable payment types include Visa, Mastercard, American Express, Jars of Honey">
            <p class="fine-print payment-note col-xs-12">All pricing shown in USD</p>
          </div>

           <div v-else-if="isCountryIndia" class="payment-info free-instead country-india-container">
            <div class="item-free item-column">
              <div class="bear-pic">
                <div class="bear-img-wrap stop-censorship">
                   <img src="./images/stop-india.png" alt="TunnelBear no longer available in India" class="stop-censorship-sign">
                </div>
              </div>
              <div class="inner-wrap">
                <div class="inner">
                  <div class="content">
                    <h2 class="h2">
                      <span >TunnelBear no longer available in India</span>
                    </h2>
                      <p>Due to the recent <a href="https://www.cert-in.org.in/PDF/CERT-In_Directions_70B_28.04.2022.pdf" target="_blank"> CERT-In regulations</a>, TunnelBear is no longer available to purchase for users in India. </p>
                    <a class="btn btn-lg read-more-btn" :href="ReadMoreCertIndia" target="_blank">Read more</a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-else class="payment-info free-instead">
            <div class="item-free item-column">
              <div class="bear-pic">
                <div class="bear-img-wrap stop-censorship">
                  <img v-if="countryCode == 'RU'" src="./images/stop-censorship-ru.png" class="stop-censorship-sign" alt="'Stop censorship' on a sign surrounded by Russian flags, held up by a bear paw">
                   <img v-else-if="countryCode == 'BY'" src="./images/stop-censorship-by.png" class="stop-censorship-sign" alt="'Stop censorship' on a sign surrounded by Belarusian flags, held up by a bear paw">
                  <img v-else src="./images/stop-censorship.png" class="stop-censorship-sign" alt="'Stop censorship' on a sign held up by a bear paw">
                </div>
              </div>
              <div class="inner-wrap">
                <div class="inner">
                  <div class="content">
                    <h2 class="h2">
                      <span v-if="countryCode == 'RU'">TunnelBear is free to use from Russia</span>
                      <span v-else-if="countryCode == 'BY'">TunnelBear is free to use from Belarus</span>
                      <span v-else>TunnelBear cannot be purchased from your country</span>
                    </h2>
                    <p v-if="countryCode == 'RU'">In order to ensure protest organizers, journalists, and at-risk individuals have access to a safe and informed internet, we've opened up our network to anyone connecting from Russia.</p>
                    <p v-else-if="countryCode == 'BY'">In order to ensure protest organizers, journalists, and at-risk individuals have access to a safe and informed internet, we've opened up our network to anyone connecting from Belarus.</p>
                    <p v-else>You can still use a free account with limited bandwidth.</p>
                    <ul v-if="!isCountryIndia" class="check-list yellow copy">
                      <li v-if="['RU', 'BY'].includes(countryCode)">10GB of secure browsing</li>
                      <li v-else>2GB of secure browsing</li>
                    </ul>
                    <router-link aria-label="Download TunnelBear for Free with a data limit of 2GB per month" class="btn btn-lg" :to="{name: 'download'}">Download</router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <wirecutter class="wirecutter">
            We spent more than 65 hours researching 53 VPN services [and] TunnelBear is the most transparent and trustworthy provider offering fast, secure connections and easy setup.
          </wirecutter>

          <div class="faq-section">
            <div class="container">
              <h2>Frequently Asked Questions</h2>
              <div class="faqs">
                <div class="faq">
                  <h6>How does TunnelBear work?</h6>
                  <p>TunnelBear encrypts your device's incoming and outgoing data. That means when an internet service provider, network owner, or even hacker tries to snoop on your online activity, all they'll see is garbled, unreadable junk.</p>
                </div>
                <div class="faq">
                  <h6>Is my online privacy worth the cost?</h6>
                  <p>Some internet service providers can legally package and sell details about everything you do online, so it really depends on how comfortable you are with your personal data being made available to others. </p>
                </div>
                <div class="faq">
                  <h6>How do I know TunnelBear is working?</h6>
                  <p>With TunnelBear ON, visit <a href="https://bearsmyip.com" target="_blank">bearsmyip.com</a> to confirm you're secure. As long as you don't disconnect, your online activity will be encrypted and appear as though it's coming from someone else's computer.</p>
                </div>
                <div class="faq">
                  <h6>Will TunnelBear slow my browsing?</h6>
                  <p>Not unless you're tunnelling to a country that's really far away. If you don't need to access something from a specific country, set TunnelBear's country selector to Fastest to ensure the best performance.</p>
                </div>
                <div class="faq">
                  <h6>How many devices can I use on one TunnelBear account?</h6>
                  <p>There is no limit on the number of devices you can use with TunnelBear. Simply visit <router-link :to="{ name: 'download' }">tunnelbear.com/download</router-link> on each device to install the TunnelBear app. Once the app is installed on your new device, log in with your existing TunnelBear account and connect to a server.</p>
                </div>
                <div class="faq">
                  <h6>Why should I use TunnelBear over other VPNs?</h6>
                  <p>Lots of reasons! It's incredibly simple to use, we're the only consumer VPN provider to perform annual security audits (<a :href="this.external.audit" target="_blank">and publish the results</a>), you don't need a lawyer to understand our <router-link :to="{ name: 'privacy-policy'}">privacy policy</router-link>, and we've got over 175,000 5-star reviews!</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import is from 'is_js'
import wirecutter from '@/components/wirecutter/wirecutter'
import zendeskWidget from '@/mixins/zendesk-widget.js'

export default {
  name: 'pricing',
  components: {
    wirecutter
  },
  data () {
    return {
      is,
      mobileSelect: 'unlimited',
      currentProduct: 'THREE_YEAR_PAYMENT',
      countryCode: null,
      isSanctioned: false,
      ReadMoreCertIndia: 'https://help.tunnelbear.com/hc/en-us/articles/9008085360539-TunnelBear-no-longer-available-in-India'
    }
  },
  mixins: [zendeskWidget],
  computed: {
    sale: function () {
      return this.$store.state.pricingService.sale
    },
    pricingValues: function () {
      return this.$store.state.pricingService.values
    },
    unlimitedUrl: function () {
      let location = { name: 'checkout' }
      if (this.sale.isLive && this.sale.couponSale && this.sale.couponRoute) {
        location = this.sale.couponRoute
      }
      return location
    },
    isCountryIndia: function () {
      return this.countryCode === 'IN'
    }
  },
  beforeMount: function () {
    this.$emit('modifyLayout', {
      bgColor: 'bg-regular'
    })
  },
  created: function () {
    this.$store.state.pricingService.getPricingData()
    this.$store.state.locationService.getCountryCode().then(isoCountry => { this.countryCode = isoCountry })
    this.$store.state.locationService.isSanctioned().then(isSanctioned => { this.isSanctioned = isSanctioned })
    if (this.sale.isLive) {
      this.currentProduct = 'YEARLY_PAYMENT_RECURRING'
    }
  }
}
</script>

<style scoped lang="stylus" src="./pricing.styl" >
