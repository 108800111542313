var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("nav", { attrs: { id: "main-header" } }, [
    _c("div", { staticClass: "nav-inner complex" }, [
      _c(
        "div",
        { staticClass: "top-bar" },
        [
          _c(
            "router-link",
            { staticClass: "logo", attrs: { to: { name: "tb-by-mcafee" } } },
            [
              _c("img", {
                staticClass: "hidden-xs",
                attrs: {
                  src: require("../../images/tb-by-mcafee.svg"),
                  alt: "TunnelBear by McAfee",
                },
              }),
              _vm._v(" "),
              _c("img", {
                staticClass: "visible-xs",
                attrs: {
                  src: require("@/images/t-grey.svg"),
                  alt: "TunnelBear by McAfee",
                },
              }),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "drawer" }, [
        _c("div", { attrs: { id: "nav-items" } }, [
          _c("div", { staticClass: "nav-menu" }, [
            _c(
              "div",
              { staticClass: "nav-item bottom" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "btn",
                    attrs: {
                      to: {
                        name: "tb-by-mcafee-checkout",
                        query: _vm.routeQueryYearly,
                      },
                    },
                  },
                  [_vm._v("Get TunnelBear")]
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }