var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("announce", [
        _vm.submitError
          ? _c(
              "p",
              {
                staticClass: "alert alert-danger",
                attrs: { "data-cy": "error--submit" },
              },
              [_vm._v(_vm._s(_vm.submitError))]
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c(
        "form",
        {
          staticClass: "form-area auth",
          attrs: { name: "loginForm", id: "login-form", novalidate: "" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.submit.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "form-row" },
            [
              _c("announce", [
                _c(
                  "p",
                  {
                    staticClass: "field-error",
                    class: { "show-error": _vm.errors.current === "email" },
                    attrs: { "data-cy": "error--email" },
                  },
                  [_vm._v("Grr... That is not a valid email")]
                ),
              ]),
              _vm._v(" "),
              _c(
                "dynamic-input",
                {
                  attrs: {
                    name: "email",
                    type: "email",
                    placeholder: _vm.emailPlaceholder,
                    autocomplete: "email",
                    required: "",
                  },
                  on: { model: _vm.emailTyping, focus: _vm.emailFocus },
                },
                [_vm._v("Email")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "form-row" },
            [
              _c("announce", [
                _c(
                  "p",
                  {
                    staticClass: "field-error",
                    class: { "show-error": _vm.errors.current === "password" },
                    attrs: { "data-cy": "error--password" },
                  },
                  [_vm._v("Grr... Please enter a valid password")]
                ),
              ]),
              _vm._v(" "),
              _c(
                "dynamic-input",
                {
                  attrs: {
                    name: "password",
                    type: "password",
                    autocomplete: "password",
                    required: "",
                  },
                  on: {
                    model: function ($event) {
                      _vm.formData.password = $event
                    },
                    blur: _vm.passwordBlur,
                    focus: _vm.passwordFocus,
                  },
                },
                [_vm._v("Password")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "submit-button",
            {
              attrs: {
                finished: _vm.finished,
                submitting: _vm.submitting,
                "data-cy": "btn--login-existing",
              },
              on: { submit: _vm.submit },
            },
            [_vm._v("Log In")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }