var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("nav-links-dropdown", {
    on: {
      dropdownClicked: function ($event) {
        return _vm.$emit("dropdownClicked")
      },
    },
    scopedSlots: _vm._u([
      {
        key: "link",
        fn: function () {
          return [
            _c(
              "router-link",
              {
                class: { current: _vm.isAppsRoute },
                attrs: { to: { name: _vm.deviceAppRoute() } },
              },
              [_vm._v("Apps")]
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "content",
        fn: function () {
          return [
            _c("ul", [
              _c(
                "li",
                { class: { current: _vm.current === "mac" } },
                [
                  _c("div", { staticClass: "device mac" }),
                  _vm._v(" "),
                  _c("router-link", { attrs: { to: { name: "mac" } } }, [
                    _vm._v("Mac"),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                { class: { current: _vm.current === "windows" } },
                [
                  _c("div", { staticClass: "device windows" }),
                  _vm._v(" "),
                  _c("router-link", { attrs: { to: { name: "windows" } } }, [
                    _vm._v("Windows"),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                { class: { current: _vm.current === "ios" } },
                [
                  _c("div", { staticClass: "device ios" }),
                  _vm._v(" "),
                  _c("router-link", { attrs: { to: { name: "ios" } } }, [
                    _vm._v("iPhone & iPad"),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                { class: { current: _vm.current === "android" } },
                [
                  _c("div", { staticClass: "device android" }),
                  _vm._v(" "),
                  _c("router-link", { attrs: { to: { name: "android" } } }, [
                    _vm._v("Android"),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                { class: { current: _vm.current === "browser" } },
                [
                  _c("div", { staticClass: "device browser" }),
                  _vm._v(" "),
                  _c("router-link", { attrs: { to: { name: "browser" } } }, [
                    _vm._v("Browser Extensions"),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                { class: { current: _vm.current === "blocker" } },
                [
                  _c("div", { staticClass: "device blocker" }),
                  _vm._v(" "),
                  _c("router-link", { attrs: { to: { name: "blocker" } } }, [
                    _vm._v("Blocker"),
                  ]),
                ],
                1
              ),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }