var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "view-wrap" },
    [
      _c("div", { staticClass: "view-content" }, [
        _c("section", { attrs: { id: "downloads" } }, [
          _c("div", { staticClass: "section-inner" }, [
            _c("div", { staticClass: "container" }, [
              _c("div", { staticClass: "section-inner-md text-center" }, [
                _c("h1", [_vm._v("Download TunnelBear")]),
                _vm._v(" "),
                _c("p", [_vm._v("Select your platform below to get started.")]),
                _vm._v(" "),
                _c("div", { staticClass: "download-row" }, [
                  _c("div", { staticClass: "download-item" }, [
                    _c("h2", [_vm._v("Desktop")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "btn-holder" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-lg dl mac",
                          attrs: {
                            "data-cy": "btn--mac-download",
                            id: "mac-dl",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.download("mac")
                            },
                          },
                        },
                        [_c("div", [_vm._v("Mac")])]
                      ),
                      _vm._v(" "),
                      _c(
                        "p",
                        { staticClass: "fine-print" },
                        [
                          _vm._v(
                            "64-bit macOS " +
                              _vm._s(this.apps.mac.osSupport) +
                              ". "
                          ),
                          _c(
                            "router-link",
                            { attrs: { to: { name: "whats-new-osx" } } },
                            [_vm._v("What's new?")]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "btn-holder" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-lg dl windows",
                          attrs: { "data-cy": "btn--pc-download", id: "pc-dl" },
                          on: {
                            click: function ($event) {
                              return _vm.download("windows")
                            },
                          },
                        },
                        [_c("div", [_vm._v("Windows")])]
                      ),
                      _vm._v(" "),
                      _c(
                        "p",
                        { staticClass: "fine-print" },
                        [
                          _vm._v(
                            "Windows " +
                              _vm._s(this.apps.windows.osSupport) +
                              ". "
                          ),
                          _c(
                            "router-link",
                            { attrs: { to: { name: "whats-new-pc" } } },
                            [_vm._v("What's New?")]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "download-item" }, [
                    _c("h2", [_vm._v("Mobile")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "btn-holder",
                        attrs: { "data-cy": "btn--ios-download" },
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "btn btn-lg dl ios",
                            attrs: { href: _vm.external.downloadLinks.ios },
                          },
                          [_c("div", [_vm._v("iOS")])]
                        ),
                        _vm._v(" "),
                        _c("p", { staticClass: "fine-print" }, [
                          _vm._v(
                            "iPads and iPhones with " +
                              _vm._s(this.apps.ios.osSupport)
                          ),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "btn-holder",
                        attrs: { "data-cy": "btn--android-download" },
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "btn btn-lg dl android",
                            attrs: { href: _vm.external.downloadLinks.android },
                          },
                          [_c("div", [_vm._v("Android")])]
                        ),
                        _vm._v(" "),
                        _c("p", { staticClass: "fine-print" }, [
                          _vm._v(
                            "Android " + _vm._s(this.apps.android.osSupport)
                          ),
                        ]),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _vm._m(0),
                _vm._v(" "),
                _c("div", { staticClass: "download-row" }, [
                  _c(
                    "div",
                    {
                      staticClass: "download-item btn-holder",
                      attrs: { "data-cy": "btn--chrome-extension" },
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "btn btn-lg grey dl chrome",
                          attrs: { href: _vm.external.downloadLinks.chrome },
                        },
                        [_c("div", [_vm._v("Chrome")])]
                      ),
                      _vm._v(" "),
                      _c(
                        "p",
                        { staticClass: "fine-print" },
                        [
                          _vm._v("Chrome 22+ "),
                          _c(
                            "router-link",
                            { attrs: { to: { name: "chrome" } } },
                            [_vm._v("Learn More")]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "download-item btn-holder",
                      attrs: { "data-cy": "btn--firefox-extension" },
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "btn btn-lg grey dl firefox",
                          attrs: { href: _vm.external.downloadLinks.firefox },
                        },
                        [_c("div", [_vm._v("Firefox")])]
                      ),
                      _vm._v(" "),
                      _c("p", { staticClass: "fine-print" }, [
                        _vm._v("Firefox 58+"),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "download-item btn-holder",
                      attrs: { "data-cy": "btn--edge-extension" },
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "btn btn-lg grey dl edge",
                          attrs: { href: _vm.external.downloadLinks.edge },
                        },
                        [_c("div", [_vm._v("Edge")])]
                      ),
                      _vm._v(" "),
                      _c("p", { staticClass: "fine-print" }, [
                        _vm._v("Edge 114+"),
                      ]),
                    ]
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "notices" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "fine-print",
                      attrs: { to: { name: "legal-notices" } },
                    },
                    [_vm._v("Notice and Attribution")]
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "fine-print",
                      attrs: {
                        href: "https://help.tunnelbear.com/hc/en-us/articles/360007120951",
                      },
                    },
                    [_vm._v("Uninstall TunnelBear")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "p",
                { staticClass: "fine-print terms-policy-text" },
                [
                  _vm._v("\n              By downloading "),
                  _c("strong", [_vm._v("TunnelBear")]),
                  _vm._v(",\n              you agree to the "),
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: { name: "terms-of-service" },
                        target: "_blank",
                      },
                    },
                    [_vm._v("Terms of Service")]
                  ),
                  _vm._v("\n              and "),
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: { name: "privacy-policy" },
                        target: "_blank",
                      },
                    },
                    [_vm._v("Privacy Policy")]
                  ),
                  _vm._v("."),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("trigger-download", { ref: "download" }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-center copy" }, [
      _c("h2", [_vm._v("Browser Extensions")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "Lightweight extensions that only tunnel data inside your browser."
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }